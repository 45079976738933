import { useRef, useState } from "react"

export const useDraggable = () => {
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const isDown = useRef(false)
  const slider = useRef(null)

  const mouseDownHandler = e => {
    isDown.current = true
    setStartX(e.pageX - slider.current.offsetLeft)
    setScrollLeft(slider.current.scrollLeft)
  }

  const mouseLeaveHandler = () => {
    isDown.current = false
  }

  const mouseUpHandler = () => {
    isDown.current = false
    setTimeout(() => {
      setIsDragging(false)
    }, 100)
  }

  const mouseMoveHandler = e => {
    if (!isDown.current) return
    e.preventDefault()
    setIsDragging(true)
    const x = e.pageX - slider.current.offsetLeft
    const walk = (x - startX) * 3 //scroll-fast
    slider.current.scrollLeft = scrollLeft - walk
  }

  return {
    slider,
    isDragging,
    mouseDownHandler,
    mouseLeaveHandler,
    mouseUpHandler,
    mouseMoveHandler,
  }
}
