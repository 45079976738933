import React, { useContext, useEffect, useRef } from "react"
import { Checkbox } from "../../../components/atoms/Checkbox"
import { parseDisclaimerBlocks } from "../../../helpers"
import { InventoryContext } from "../../../contexts/Inventory/context"
import {
  addQueryOption,
  removeQueryOption,
} from "../../../contexts/Inventory/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { CheckboxFilterProps } from "./CheckboxFilter.d"
import { useTealiumContext } from "../../../contexts/Tealium"
import { FiltersContext } from "../../../contexts/Filters/context"
import {
  addSelectedFilter,
  removeSelectedFilter,
} from "../../../contexts/Filters/actions"

const CheckboxFilterNoSuboptions: React.FC<CheckboxFilterProps> = ({
  value,
  active,
  paramName,
  isExpanded,
  index,
  parentLeft,
}) => {
  const [disclaimerState, modalDispatch] = useContext(DisclaimersContext)
  const [filtersState, dispatchFilters] = useContext(FiltersContext)

  const label = value.description || value.name || value.title
  value.value = Array.isArray(value.value) ? value.value[0] : value.value

  let checked: boolean

  // If it has accessory codes and they start with XX, this is an X-series option
  if (value.accessoryCodes && value.accessoryCodes.startsWith("XX")) {
    paramName = "accessoryModels"
    value.value = value.accessoryCodes
    checked = filtersState.selectedFilters.accessoryModels?.includes(
      value.value
    )
  } else {
    checked = filtersState.selectedFilters[paramName]?.includes(
      value.value || label
    )
  }

  //Tealium
  const {
    tealPageData,
    removeInventoryFilterFromQueue,
    addInventoryFilterToQueue,
  } = useTealiumContext()

  return (
    <Checkbox
      label={parseDisclaimerBlocks(
        label,
        selection => modalDispatch(toggleDisclaimersModal(selection)),
        parentLeft
      )}
      checked={checked}
      onChange={() => {
        if (checked) {
          dispatchFilters(removeSelectedFilter(paramName, value.value || label))
          removeInventoryFilterFromQueue({
            refinement_value: `${tealPageData?.page_type}|${
              tealPageData?.page_name
            }|${paramName == "year" ? "Year" : paramName}|${label}`,
          })
        } else {
          dispatchFilters(addSelectedFilter(paramName, value.value || label))
          addInventoryFilterToQueue({
            refinement_value: `${tealPageData?.page_type}|${
              tealPageData?.page_name
            }|${paramName == "year" ? "Year" : paramName}|${label}`,
          })
        }
      }}
      // disabled={!active}
      backgroundColor={value.hex}
      backgroundImageUrl={value?.swatchCropped}
      tabIndex={isExpanded ? 0 : -1}
      analyticsId={
        paramName ? `${paramName} filter:refinement:${index + 1}` : null
      }
    />
  )
}

export default CheckboxFilterNoSuboptions
