import { createContext, useReducer } from "react"
import { FiltersState } from "./filters.d"
import filtersReducer, { defaultState } from "./reducers"

export const FiltersContext = createContext<
  [FiltersState, React.Dispatch<any>]
>([defaultState, () => {}])

export const FiltersProvider: React.FC = ({ children }) => {
  const [filtersState, dispatch] = useReducer(filtersReducer, {
    ...defaultState,
  })

  return (
    <FiltersContext.Provider value={[filtersState, dispatch]}>
      {children}
    </FiltersContext.Provider>
  )
}
