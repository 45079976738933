import React, { useContext, useEffect } from "react"
import tw from "twin.macro"
import { Vehicle } from "../../../global"
import InventoryResultCard from "./InventoryResultCard"
import { useInView } from "react-intersection-observer"
import Lottie from "react-lottie-player"

import lottieJson from "../../../animations/14717-sedan-car-animation.json"
import { LanguageContext } from "../../../contexts/Language"
import NoMoreInventoryCard from "./NoMoreInventoryCard"
import NoExactMatchesCard from "./NoExactMatchesCard"
import { Filter } from "../../../clients/FiltersClient.d"
import { LocationContext } from "../../../contexts/Location"

const InventoryResults: React.FC<{
  inventoryQuery: any
  isLoading: boolean
  filtersAreDisplayed?: boolean
  handleLoadMore: () => void
  showNoResultsNotice: boolean
  seriesFilters: Filter[]
  series: string
}> = ({
  inventoryQuery,
  isLoading,
  filtersAreDisplayed,
  handleLoadMore,
  showNoResultsNotice,
  seriesFilters,
  series,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "300px",
  })
  const { _ } = useContext(LanguageContext)
  const [{ dealers }] = useContext(LocationContext)

  useEffect(() => {
    if (inView) handleLoadMore()
  }, [inView])

  return (
    <section css={[tw`relative`]}>
      <div
        css={[
          tw`relative col-span-12`,
          tw`sm:(col-span-5 block)`,
          tw`md:(block col-span-6)`,
          tw`lg:(col-span-7)`,
          tw`xl:(col-span-8)`,
          tw`2xl:(col-span-9)`,
        ]}
      >
        <div css={[tw`min-h-[425px] pb-20`]}>
          <div
            css={[
              tw`relative grid grid-cols-1 gap-8`,
              tw`md:(grid-cols-1 gap-8)`,
              tw`lg:(grid-cols-2 gap-8)`,
              tw`xl:(grid-cols-3 gap-8)`,
              tw`2xl:(grid-cols-4 gap-8)`,
            ]}
            className="optimizelyInventoryResultsGrid"
          >
            {inventoryQuery?.data?.pages
              .sort()
              .map((page: { vehicles: Vehicle[] }) =>
                page?.vehicles?.sort().map((vehicle: Vehicle, i) => {
                  const watchCard = i === Math.floor(page?.vehicles?.length / 2)
                  return (
                    <InventoryResultCard
                      vehicle={vehicle}
                      cardRef={watchCard ? ref : null}
                      index={i}
                      location={"inventory"}
                    />
                  )
                })
              )}
          </div>
          {showNoResultsNotice &&
            dealers?.length > 0 &&
            (!inventoryQuery.hasNextPage &&
            inventoryQuery?.data?.pages[0]?.count > 0 ? (
              <NoMoreInventoryCard
                seriesFilters={seriesFilters}
                seriesName={series}
              />
            ) : (
              <NoExactMatchesCard
                seriesFilters={seriesFilters}
                seriesName={series}
              />
            ))}
          {isLoading && (
            <div
              css={[
                tw`absolute left-0 right-0 bottom-0 bg-white z-10 bg-opacity-70 flex items-center justify-center h-96`,
              ]}
            >
              <div>
                <span
                  css={[
                    tw`block mt-4 text-lg text-gray-700 font-bold text-center leading-tight`,
                  ]}
                >
                  {_("Hang tight, we're fetching the")} <br />
                  {_("most current inventory for you")}
                </span>
                <div>
                  <Lottie loop animationData={lottieJson} play />
                </div>
              </div>
            </div>
          )}
          {/* User is out of area (no dealers) */}
          {dealers?.length === 0 && !isLoading && (
            <p css={tw`text-center p-4`}>
              {_(
                "Sorry, we couldn't find any inventory in your selected area. Please try expanding your search area, or enter a new location."
              )}
            </p>
          )}
        </div>
      </div>
    </section>
  )
}

export default InventoryResults
