import tw from "twin.macro"
import { ColorSwatchTooltipProps } from "./ColorFilter.d"
import { useEffect, useRef, useState } from "react"
import useViewportSize from "../../../hooks/useViewportSize"

const ColorSwatchTooltip: React.FC<ColorSwatchTooltipProps> = ({
  children,
  active = false,
  swatchContainer,
  parentLeft,
  parentRight,
  showName,
  ...remainingProps
}): JSX.Element => {
  const boxRef = useRef(null)
  const [show, setShow] = useState(false)
  const { width } = useViewportSize()
  const isMobileView = width < 1024

  useEffect(() => {
    if (showName) {
      isMobileView
        ? setTimeout(() => {
            setShow(true)
          }, 500)
        : setShow(true)
    } else {
      setShow(false)
    }
  }, [showName])
  if (boxRef.current && boxRef.current.style) {
    const distanceFromLeft =
      parentLeft - swatchContainer?.current.getBoundingClientRect().left
    const distanceFromRight =
      parentRight - swatchContainer?.current.getBoundingClientRect().right
    if (distanceFromLeft > -115 && distanceFromLeft <= 0) {
      //skip
    } else if (distanceFromRight < 115 && distanceFromRight >= 0) {
      boxRef.current.style.transform = `translateX(-170px)`
    } else {
      boxRef.current.style.transform = `translateX(-40%)`
    }
  }
  return (
    <div
      css={[
        tw`relative w-[230px] mx-auto opacity-0 transition-opacity duration-300 ease-in-out`,
        show && tw`opacity-100`,
      ]}
    >
      <div
        css={[
          `border-left: 10px solid transparent; border-right: 10px solid transparent; border-bottom: 10px solid;`,
          tw`absolute top-0 left-4 border-b-black`,
        ]}
      />
      <div
        ref={boxRef}
        css={[
          tw`absolute z-50 top-2.5 bg-black p-2 rounded-md text-white text-center w-[230px]`,
        ]}
      >
        {children}
      </div>
    </div>
  )
}

export default ColorSwatchTooltip
