import React, { useContext, useEffect, useRef, useState } from "react"
import { parseDisclaimerBlocks } from "../../../helpers"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { SwitchFilterProps } from "./SwitchFilter.d"
import { useTealiumContext } from "../../../contexts/Tealium"
import { Switch } from "../../atoms/Switch"
import tw from "twin.macro"
import { FiltersContext } from "../../../contexts/Filters/context"
import {
  addSelectedFilter,
  removeSelectedFilter,
} from "../../../contexts/Filters/actions"
import { LanguageContext } from "../../../contexts/Language"

const SwitchFilter: React.FC<SwitchFilterProps> = ({
  values,
  paramName,
  parentLeft,
}) => {
  const [filtersState, dispatchFilters] = useContext(FiltersContext)
  const [disclaimerState, modalDispatch] = useContext(DisclaimersContext)
  const parentContainer = useRef(null)
  const { _ } = useContext(LanguageContext)

  //Tealium
  const {
    tealPageData,
    removeInventoryFilterFromQueue,
    addInventoryFilterToQueue,
  } = useTealiumContext()

  const getDisclaimerParentPosition = (): { left: number; right: number } => {
    if (!parentContainer?.current) {
      return { left: null, right: null }
    }
    const parentContainerRect =
      parentContainer?.current?.getBoundingClientRect()
    return {
      left: parentContainerRect?.left + 10,
      right: parentContainerRect?.right + 10,
    }
  }

  return (
    <div css={[tw`flex flex-row gap-1 md:gap-5`]} ref={parentContainer}>
      {values.map((value: { name: any; value: any[] }, index: any) => {
        const label = _(value.name)
        value.value = Array.isArray(value.value) ? value.value[0] : value.value
        let checked: boolean
        checked = filtersState.selectedFilters[paramName]?.includes(
          value.value || label
        )
        const [parentLeft, setParentLeft] = useState(null)
        const [parentRight, setParentRight] = useState(null)
        useEffect(() => {
          const disclaimerParentPosition = getDisclaimerParentPosition()
          setParentRight(disclaimerParentPosition.right)
          setParentLeft(disclaimerParentPosition.left)
        }, [])
        return (
          <Switch
            key={`switch-${value.name}`}
            label={parseDisclaimerBlocks(
              label,
              selection => modalDispatch(toggleDisclaimersModal(selection)),
              parentLeft,
              parentRight,
              285
            )}
            checked={checked}
            onChange={() => {
              if (checked) {
                dispatchFilters(
                  removeSelectedFilter(paramName, value.value || label)
                )
                removeInventoryFilterFromQueue({
                  refinement_value: `${tealPageData?.page_type}|${
                    tealPageData?.page_name
                  }|${
                    paramName === "available" ? "Availability" : paramName
                  }|${label}`,
                })
              } else {
                dispatchFilters(
                  addSelectedFilter(paramName, value.value || label)
                )
                addInventoryFilterToQueue({
                  refinement_value: `${tealPageData?.page_type}|${
                    tealPageData?.page_name
                  }|${
                    paramName === "available" ? "Availability" : paramName
                  }|${label}`,
                })
              }
            }}
            css={[tw`gap-2`]}
            analytics-id={
              paramName
                ? `${
                    paramName === "available" ? "availability" : paramName
                  } filter:refinement:${index + 1}`
                : null
            }
          />
        )
      })}
    </div>
  )
}

export default SwitchFilter
