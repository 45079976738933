import React, { useContext, useEffect, useRef, useState } from "react"
import { scrollIntoView } from "seamless-scroll-polyfill"
import tw from "twin.macro"
import { Filter } from "../../../clients/FiltersClient.d"
import {
  clearFiltersModal,
  closeFiltersModal,
} from "../../../contexts/Filters/actions"
import { FiltersContext } from "../../../contexts/Filters/context"
import { setQueryFromFilters } from "../../../contexts/Inventory/actions"
import { InventoryContext } from "../../../contexts/Inventory/context"
import { LanguageContext } from "../../../contexts/Language"
import { LocationContext } from "../../../contexts/Location"
import { useTealiumContext } from "../../../contexts/Tealium"
import { checkAllAccessoryCodesExist } from "../../../helpers"
import AnimatedIcon from "../../atoms/Accordion/AnimatedIcon"
import { Badge } from "../../atoms/Badge"
import { Modal } from "../../atoms/Modal"
import { AccessoryFilter } from "../../molecules/AccessoryFilter"
import CheckboxFilter from "../../molecules/CheckboxFilter/CheckboxFilter"
import ColorFilter from "../../molecules/ColorFilter/ColorFilter"
import SwitchFilter from "../../molecules/SwitchFilter/SwitchFilter"
import StaticFilters from "./StaticFilters"
import { Loader } from "../../atoms/Icon"

const Filters: React.FC<{
  filters: Filter[]
  isLoading: boolean
  modalOpen: boolean
  closeModal: any
}> = ({ filters, modalOpen, closeModal }) => {
  const filterTypes = new Map<string, React.FC>([
    ["CheckBox", CheckboxFilter],
    ["Switch", SwitchFilter],
    ["ColorSelector", ColorFilter],
    ["AccessoryCheckbox", AccessoryFilter],
  ])
  const [{ dealers }] = useContext(LocationContext)
  const [state, dispatch] = useContext(InventoryContext)
  const [filtersState, dispatchFilters] = useContext(FiltersContext)
  const { _ } = useContext(LanguageContext)
  const getFilterCountFromState = (filter: Filter) => {
    let filterState = 0

    if (Array.isArray(filtersState.selectedFilters[filter.paramName])) {
      filterState = filtersState.selectedFilters[filter.paramName].length
    } else if (filtersState.selectedFilters[filter.paramName]) {
      filterState++
    }

    // Determine if custom x series custom models and modelGroups are selected.
    if (
      filter.paramName === "modelNumber" &&
      filtersState.selectedFilters?.accessoryModels
    ) {
      // X Series Custom Models
      const modelsWithAccessoryCodes = filter?.values?.filter(
        value => value?.accessoryCodes
      )
      modelsWithAccessoryCodes?.length > 0 &&
        modelsWithAccessoryCodes.forEach(model => {
          const areInState = checkAllAccessoryCodesExist(
            model?.accessoryCodes,
            filtersState.selectedFilters["accessoryModels"]
          )
          areInState && filterState++
        })

      // X Series Custom Model Groups
      const modelGroupsWithAccessoryCodes = filter.values
        ?.map(({ models }) => {
          const modelsWithAccessoryCodes = models?.filter(
            model => model.accessoryCodes
          )
          return modelsWithAccessoryCodes
        })
        ?.filter(value => value?.length > 0)
        ?.flat()
      modelGroupsWithAccessoryCodes?.length > 0 &&
        modelGroupsWithAccessoryCodes.forEach(model => {
          const areInState = checkAllAccessoryCodesExist(
            model?.accessoryCodes,
            filtersState.selectedFilters["accessoryModels"]
          )
          areInState && filterState++
        })
    }

    // Add selected X-Series to accessory filter count
    if (filter.paramName === "accessory") {
      filter.values?.forEach(accessoryFilter => {
        if (accessoryFilter?.subCategory !== "X-Series") return
        const areInState = checkAllAccessoryCodesExist(
          accessoryFilter.value,
          filtersState.selectedFilters["accessoryModels"]
        )

        areInState && filterState++
      })
    }

    return filterState
  }

  const sortedFilter = filters?.reduce(
    (allFilters, filter) => {
      allFilters[
        filter.name == "PRICE" || filter.name == "AVAILABILITY"
          ? "staticFilters"
          : "remainingFilters"
      ]?.push(filter)
      return allFilters
    },
    { staticFilters: [], remainingFilters: [] }
  )
  //Tealium
  const {
    tealPageData,
    setRefineInAction,
    inventoryFilters,
    clearAllInventoryFilterQueue,
  } = useTealiumContext()

  const handleSave = () => {
    //close modal
    dispatchFilters(closeFiltersModal())
    //update inventory query
    const allDealers = dealers.map(dealer => dealer.DealerCode).join(",")
    const { radius, ...remainingSelectedFilters } = filtersState.selectedFilters
    dispatch(
      setQueryFromFilters(
        remainingSelectedFilters,
        filtersState.currentDealerFilter,
        allDealers
      )
    )
    //trigger tealium refinement event that fires save event and passes filters to fire on inventory results load
    setRefineInAction({
      inProgress: true,
      tealiumEvent: "cta_click",
      couponModuleText: "Save",
      filtersQueue: inventoryFilters,
      filterValues: sortedFilter.remainingFilters,
    })
  }

  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      closeStyled={true}
      css={[
        tw` pt-4 px-4 lg:(pt-4 px-8 rounded-[20px]) max-w-[900px] min-h-[fit-content] max-h-full lg:max-h-[80vh] top-0 bottom-0 relative`,
      ]}
    >
      <div
        css={[
          tw`overflow-y-scroll overflow-x-hidden h-[calc(93vh - 1.25rem)] pt-[8vh] pb-20 lg:h-[calc(71vh - 1.25rem)]  grid grid-cols-1 lg:(grid-cols-2 gap-0 pb-12 px-8 -mx-4)`,
        ]}
        className="optimizelyInventoryFilters"
      >
        {/* Don't change on Series Selection */}
        <StaticFilters sortedFilters={sortedFilter?.staticFilters} />
        {/* Rerender with series selection */}

        {sortedFilter?.remainingFilters ? (
          sortedFilter?.remainingFilters.map((filter: Filter, index) => {
            const FilterComponent: any = filterTypes.get(filter.type)
            const content = useRef(null)
            const [isExpanded, setisExpanded] = useState(false)
            const [transitionEnded, setTransitionEnded] = useState(false)
            const elementRef = useRef(null)
            useEffect(() => {
              const element = elementRef.current
              const handleTransitionEnd = () => {
                setTimeout(() => {
                  setTransitionEnded(true)
                }, 700) // Set the timeout to 500ms
              }
              element?.addEventListener("transitionend", handleTransitionEnd)
              return () => {
                element?.removeEventListener(
                  "transitionend",
                  handleTransitionEnd
                )
              }
            }, [modalOpen])

            useEffect(() => {
              setTimeout(() => {
                if (isExpanded && filter.name !== "INTERIOR COLOR") {
                  scrollIntoView(elementRef.current, {
                    behavior: "smooth",
                    inline: "start",
                    block: "center",
                  })
                } else if (isExpanded) {
                  scrollIntoView(elementRef.current, {
                    behavior: "smooth",
                    inline: "end",
                    block: "center",
                  })
                }
              }, 300)
            }, [isExpanded])

            useEffect(() => {
              if (modalOpen) {
                setisExpanded(false)
              }
            }, [modalOpen])
            const yearFilter = filters.find(filter => filter.name === "YEAR")
            if (!FilterComponent) return null
            return (
              <div
                key={index}
                css={[
                  filter.name === "YEAR" ? tw`col-span-1 mr-2` : tw`col-span-2`,
                ]}
              >
                <div
                  ref={elementRef}
                  css={[
                    transitionEnded
                      ? tw`overflow-visible`
                      : tw`overflow-hidden`,
                    isExpanded && tw`transition-all ease-out duration-500 pb-6`,
                  ]}
                >
                  <button
                    type="button"
                    css={[
                      tw`flex justify-between items-center w-full py-4 px-4 mb-1`,
                      tw`focus-visible:(outline-gray)`,
                      "-webkit-tap-highlight-color: transparent;",
                      filter.name === "YEAR" &&
                      yearFilter.values.length < 2 &&
                      !state.year
                        ? tw`hidden`
                        : tw`flex`,
                    ]}
                    aria-expanded={isExpanded}
                    onClick={() => {
                      setisExpanded(!isExpanded)
                      setTransitionEnded(!transitionEnded)
                    }}
                  >
                    <h5 css={[tw`text-sm mt-0 font-semibold inline`]}>
                      {_(filter.name).toUpperCase()}
                      {getFilterCountFromState(filter) > 0 ? (
                        <Badge
                          color={"toyotaRed"}
                          css={[tw` ml-3 rounded-full text-sm`]}
                        >
                          {getFilterCountFromState(filter)}
                        </Badge>
                      ) : null}
                    </h5>
                    <AnimatedIcon isExpanded={isExpanded} />
                  </button>
                  <div
                    ref={node => {
                      content.current = node
                      elementRef.current = node
                    }}
                    css={[
                      tw`transition-all ease-out duration-500 opacity-100 px-4 pt-1 h-0`,
                      transitionEnded
                        ? tw`overflow-visible`
                        : tw`overflow-hidden`,
                      isExpanded
                        ? `height: ${
                            content.current && content.current.scrollHeight
                          }px;`
                        : tw`overflow-hidden`,
                    ]}
                  >
                    <FilterComponent {...filter} isExpanded={isExpanded} />
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <Loader
            color="red-400"
            css={[tw`max-h-20 animate-spin mt-24 mb-28 mx-auto`]}
          />
        )}
      </div>
      <div
        css={[
          tw`sticky bottom-0 h-[7vh] w-full bg-white flex gap-12 items-center justify-end border-t-gray-200 border-t px-4 z-50`,
        ]}
      >
        <button
          css={[tw`text-red-400 text-sm`]}
          onClick={() => {
            dispatchFilters(clearFiltersModal()), clearAllInventoryFilterQueue()
          }}
        >
          {_("Clear All")}
        </button>
        <button
          css={[tw`font-semibold hover:(text-toyotaRed underline)`]}
          onClick={handleSave}
          analytics-id={"save:filter modal:"}
        >
          {_("Save")}
        </button>
      </div>
    </Modal>
  )
}

export default Filters
