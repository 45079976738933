import React, { useContext, useEffect, useRef, useState } from "react"
import { parseDisclaimerBlocks } from "../../../helpers"
import tw from "twin.macro"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import useComponentId from "../../../hooks/useIdGenerator"
import { AccessoryCheckboxProps } from "./AccessoryFilter.d"
import { Image } from "../../atoms/Image"
import { Card } from "../../atoms/Card"
import CheckMarkInCircle from "../../atoms/Icon/CheckMarkInCircle"
import Icon from "../../atoms/Icon"
import { Button } from "../../atoms/Button"
import { LanguageContext } from "../../../contexts/Language"
import styled from "@emotion/styled"

const AccessoryCheckbox: React.FC<AccessoryCheckboxProps> = ({
  title,
  value,
  checked,
  imageSrc,
  description,
  handleCheck,
  handleUncheck,
  parentRight,
  parentLeft,
  ...remainingProps
}) => {
  const [disclaimerState, modalDispatch] = useContext(DisclaimersContext)
  const { _ } = useContext(LanguageContext)
  const [isFlipped, setIsFlipped] = useState(false)
  const [isMouseOnFlipButton, setIsMouseOnFlipButton] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)
  const uuid = useComponentId()
  const flipButtonRef = useRef<HTMLDivElement>(null)
  const descriptionContainer = useRef<HTMLDivElement>(null)
  const handleFlip = () => {
    setTimeout(() => {
      setIsFlipped(!isFlipped)
    }, 500)
  }
  const getParentPosition = (ref: any): { left: number; right: number } => {
    const parentContainerRect = ref?.current?.getBoundingClientRect()

    return {
      left: parentContainerRect?.left + 4,
      right: parentContainerRect?.right - 2,
    }
  }

  const { left, right } = getParentPosition(descriptionContainer)
  const isMouseInBound = (e: { clientX: number; clientY: number }) => {
    if (flipButtonRef?.current) {
      const eleBounds = flipButtonRef?.current?.getBoundingClientRect()
      if (
        e.clientX >= eleBounds.left &&
        e.clientX <= eleBounds.right &&
        e.clientY >= eleBounds.top &&
        e.clientY <= eleBounds.bottom
      ) {
        setIsMouseOnFlipButton(true)
      } else {
        setIsMouseOnFlipButton(false)
      }
    } else {
      setIsMouseOnFlipButton(false)
    }
  }
  useEffect(() => {
    if (!flipButtonRef?.current) {
      setIsMouseOnFlipButton(false)
    }
  }, [isFlipped])

  return (
    <label htmlFor={`${title}-${uuid}`} {...remainingProps}>
      <div
        onClick={e => {
          isFlipped && handleFlip()
        }}
      >
        <div
          css={[
            tw`relative flex rounded-lg shadow-4 relative cursor-pointer h-auto w-[18rem] min-h-[19rem]`,
            checked && tw`ring-2 ring-red-400`,
            `perspective: 1000px;
            transition: transform 0.8s;
            transform-style: preserve-3d;
            position: relative;
            transform: ${isFlipped ? "rotateY(180deg)" : "rotateY(0deg)"};
  `,
          ]}
        >
          {isFlipped ? (
            <div
              css={[
                tw`p-3 flex flex-col items-center justify-center font-bold`,
                `backface-visibility: hidden;
                transform: rotateY(180deg);`,
              ]}
            >
              <div css={[tw`flex w-full text-lg font-semibold text-left`]}>
                {parseDisclaimerBlocks(
                  title,
                  titleSelection =>
                    modalDispatch(toggleDisclaimersModal(titleSelection)),
                  left,
                  right,
                  240
                )}
              </div>
              <div
                css={[
                  tw`h-40 flex flex-col overflow-y-auto pr-5 my-3 text-base font-book`,
                  disclaimerState.activeDisclaimerPopoverId &&
                    tw`overflow-y-scroll`,
                ]}
                ref={descriptionContainer}
              >
                <div className="accCardDescription">
                  {parseDisclaimerBlocks(
                    description?.replace(/\u0014/g, " ")?.replaceAll('"', ""),
                    selection =>
                      modalDispatch(toggleDisclaimersModal(selection)),
                    left,
                    right,
                    200
                  )}
                </div>
              </div>
              <div css={[tw`flex w-full justify-between items-center mb-2`]}>
                <Button
                  primary
                  css={[tw``]}
                  onClick={() => {
                    if (inputRef.current.checked) {
                      handleUncheck(inputRef.current.value)
                    } else {
                      handleCheck(inputRef.current.value)
                    }
                  }}
                >
                  {checked ? _("Remove") : _("Add to Filter")}
                </Button>
                <button
                  onClick={handleFlip}
                  css={[tw`w-12 flex justify-end hover:(text-toyotaRed)`]}
                >
                  <Icon.Chevron
                    direction="left"
                    css={tw`w-7 h-7`}
                    strokeWidth="3"
                  />
                </button>
              </div>
            </div>
          ) : (
            <div
              css={[
                tw`flex flex-col flex-1 font-bold`,
                `backface-visibility: hidden;`,
              ]}
            >
              <Image
                src={imageSrc}
                css={tw`w-full h-52 object-cover z-0 rounded-t-lg`}
              />
              {checked && (
                <CheckMarkInCircle
                  color="red-400"
                  checkmarkColor="white"
                  css={tw`absolute top-0 right-2 w-6 mx-auto mt-2 z-10`}
                />
              )}
              <div css={[tw`flex p-2 flex-1 w-full`]}>
                <p css={tw`flex p-2 w-full text-left`}>
                  {parseDisclaimerBlocks(
                    title,
                    selection =>
                      modalDispatch(toggleDisclaimersModal(selection)),
                    parentLeft,
                    parentRight,
                    200,
                    200
                  )}
                </p>
                <div
                  ref={flipButtonRef}
                  onMouseEnter={isMouseInBound}
                  onMouseLeave={isMouseInBound}
                  css={[tw`flex justify-end items-center p-2 w-min`]}
                >
                  <button
                    onClick={handleFlip}
                    css={[tw`w-12 flex justify-end hover:(text-toyotaRed)`]}
                  >
                    <Icon.Chevron css={tw`w-5 h-5`} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <input
        ref={inputRef}
        type="checkbox"
        id={`${title}-${uuid}`}
        css={tw`appearance-none h-0`}
        value={value}
        disabled={
          isMouseOnFlipButton ? true : false || isFlipped ? true : false
        }
        onChange={e => {
          if (e?.target?.checked) {
            handleCheck(e.target.value)
          } else {
            handleUncheck(e.target.value)
          }
        }}
        className="peer"
        checked={checked}
      />
    </label>
  )
}

export default AccessoryCheckbox
