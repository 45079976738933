import React, { useContext } from "react"
import tw from "twin.macro"
import { setSelectedFilterOption } from "../../../contexts/Filters/actions"
import { FiltersContext } from "../../../contexts/Filters/context"
import { LocationContext } from "../../../contexts/Location"
import { setRadius } from "../../../contexts/Location/actions"
import { useTealiumContext } from "../../../contexts/Tealium"
import { defaultRadiusOptions } from "../../../helpers"
import { Pill } from "../../atoms/Pill"
import { RadiusSelectorProps } from "./RadiusSelector.d"

const RadiusSelector: React.FC<RadiusSelectorProps> = (): JSX.Element => {
  const [{ radius }, dispatch] = useContext(LocationContext)
  const [filtersState, dispatchFilters] = useContext(FiltersContext)

  const radiusOptions = defaultRadiusOptions

  // Tealium
  const { tealPageData, swapInventoryFilterInQueue } = useTealiumContext()

  return (
    <div css={[tw`flex items-center mt-4`]}>
      {radiusOptions.map((radiusOption: number, i: number) => (
        <Pill
          key={`radius-${radiusOption}`}
          noShadow
          selected={radiusOption === radius}
          alignment="center"
          onClick={() => {
            dispatch(setRadius(radiusOption))
            dispatchFilters(setSelectedFilterOption("radius", radiusOption))
            swapInventoryFilterInQueue(
              {
                refinement_value: `${tealPageData?.page_type}|${tealPageData?.page_name}|Radius|${radiusOption}`,
              },
              `${tealPageData?.page_type}|${tealPageData?.page_name}|Radius|`
            )
          }}
          analytics-id={`radius filter:location:${i + 1}`}
        >
          {radiusOption}mi
        </Pill>
      ))}
    </div>
  )
}

export default RadiusSelector
