import React, { useRef } from "react"
import tw from "twin.macro"
import useComponentId from "../../../hooks/useIdGenerator"
import { GenericRadioProps } from "./Radio.d"

/**
 * Generic radio input that hides the circle input and looks like a button
 *
 * @param {string} label - The label for the radio input
 * @param {string} value - The value for the radio input
 * @param {string} name - The name for the radio input
 * @param {function} handleChange - The function to handle the change of the radio input
 * @returns <label></label>
 */

const GenericRadio: React.FC<GenericRadioProps> = ({
  label,
  value,
  name,
  handleChange,
  selectedStyles,
  defaultChecked = false,
  ...remainingProps
}) => {
  const inputRef = useRef()
  const uuid = useComponentId()

  return (
    <label htmlFor={`${label}-${uuid}`}>
      <div
        css={[
          tw`text-black cursor-pointer`,
          (inputRef?.current?.checked || defaultChecked) &&
            (selectedStyles ? selectedStyles : tw`bg-red-400 text-white`),
        ]}
        {...remainingProps}
      >
        {label}
      </div>
      <input
        type="radio"
        id={`${label}-${uuid}`}
        css={tw`appearance-none h-0`}
        value={value}
        name={name}
        ref={inputRef}
        onChange={e => handleChange(e.target.value)}
        defaultChecked={defaultChecked}
      />
    </label>
  )
}

export default GenericRadio
