import { seriesNameMap } from "../helpers"
import { FiltersParams, FiltersResponse } from "./FiltersClient.d"

export class FiltersClient {
  private static url: string = `${process.env.GATSBY_API_URL}/filters`
  private static key: string = process.env.GATSBY_API_KEY

  /**
   * Makes an arbitrary call to the Filter API
   *
   * @param {"GET"|"POST"|"DELETE"|"UPDATE"} method - Which HTTP method to use
   * @param {FiltersParams} params - Key/value object that will be converted to URL query params in the call
   *
   * @returns {Promise<T>} - JSON response from the API
   */
  private static fetch = async <T>(
    method: "GET" | "POST" | "DELETE" | "UPDATE",
    params?: FiltersParams
  ): Promise<T> => {
    const processedParams = this.processParams(params)

    const url = `${this.url}/${
      processedParams && "?" + new URLSearchParams(processedParams)
    }`

    const config = {
      method: method,
      headers: {
        "x-api-key": this.key,
      },
    }

    try {
      const res = await fetch(url, config)
      const json: T = await res.json()
      return json
    } catch (error) {
      console.error(`Couldn't fetch filters.`, error)
    }
  }

  /**
   * Prepares an object of api params
   *
   * @param {FiltersParams} params - Params object to process and flatten
   *
   * @returns - Object with arrays and objects flattened
   */
  private static processParams = (params: FiltersParams) => {
    let processedParams: any = {}

    for (const key in params) {
      const value = params[key]

      // If slug or alternative name was passed in, get seriesName.
      if (key === "series") {
        const seriesName = seriesNameMap.get(value)
        processedParams[key] = seriesName ? seriesName : value
        continue
      }

      if (Array.isArray(value)) {
        // If it's an array of objects, flatten to key:value,key:value
        if (typeof value[0] === "object") {
          processedParams[key] = value
            .map((e: { key: string; value: string }) => `${e.key}:${e.value}`)
            .join(",")
          // If it's just an array, comma separate
        } else {
          processedParams[key] = value.join(",")
        }
      } else {
        processedParams[key] = value
      }
    }

    return processedParams
  }

  /**
   * Get all filters by specific series
   *
   * @param {string} series - The seriesName to search for
   * @param {FiltersParams} options - Additional parameters for the query
   *
   * @returns {FiltersResponse} - Array of Filter which matches query
   */
  public static getBySeries = async (
    series: string,
    options?: FiltersParams
  ) => {
    if (!options.dealer) return
    return await this.fetch<FiltersResponse>("GET", {
      ...options,
      series: series,
    })
  }
}
