import { ACTION_TYPES } from "./actions"
import { FiltersState } from "./filters.d"

export const defaultState: FiltersState = {
  drawerOpen: false,
  modalOpen: false,
  selectedFilters: [],
  currentDealerFilter: null,
}

const filtersReducer = (
  state: FiltersState,
  action: { type: ACTION_TYPES; payload?: any }
) => {
  switch (action.type) {
    case ACTION_TYPES.TOGGLE_FILTERS_DRAWER:
      return {
        ...state,
        drawerOpen: !state.drawerOpen,
      }
    case ACTION_TYPES.CLOSE_FILTERS_MODAL:
      return {
        ...state,
        modalOpen: false,
      }
    case ACTION_TYPES.OPEN_FILTERS_MODAL:
      return {
        ...state,
        modalOpen: true,
      }
    case ACTION_TYPES.UPDATE_DEALERS_FILTER:
      return {
        ...state,
        currentDealerFilter: action.payload,
      }
    case ACTION_TYPES.CLEAR_SELECTED_FILTERS:
      const { series, currentDealerFilter, ...filtersToClear } =
        state.selectedFilters
      return {
        ...state,
        currentDealerFilter: null,
        selectedFilters: { series: series },
      }
    case ACTION_TYPES.UPDATE_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: action.payload,
      }

    case ACTION_TYPES.ADD_SELECTED_FILTERS:
      let currentValues: any[] = []
      if (state.selectedFilters[action.payload.option]) {
        typeof state.selectedFilters[action.payload.option] === "object"
          ? (currentValues = [...state.selectedFilters[action.payload.option]])
          : (currentValues = [state.selectedFilters[action.payload.option]])
      }
      const value = Array.isArray(action.payload.value)
        ? action.payload.value
        : [action.payload.value]
      const { selectedFilters, ...remainingState } = state
      return {
        ...remainingState,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.option]: [...currentValues, ...value],
        },
      }
    case ACTION_TYPES.SET_SELECTED_FILTER_OPTION: {
      const { selectedFilters, ...remainingState } = state
      return {
        ...remainingState,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.option]: action.payload.value,
        },
      }
    }
    case ACTION_TYPES.REMOVE_SELECTED_FILTERS:
      const currentFilterValues = Array.isArray(
        state.selectedFilters[action.payload.option]
      )
        ? state.selectedFilters[action.payload.option]
        : [state.selectedFilters[action.payload.option]]

      // const indexToRemove = currentFilterValues.indexOf(action.payload.value)
      const thisValue = Array.isArray(action.payload.value)
        ? action.payload.value
        : [action.payload.value]

      // if value is passed as an empty array, remove all values and the param
      let newValue = []
      if (thisValue.length !== 0) {
        newValue =
          currentFilterValues?.filter(
            (fValue: any) => !action.payload.value.includes(fValue)
          ) || []
      }

      if (newValue.length > 0) {
        return {
          ...state,
          selectedFilters: {
            ...state.selectedFilters,
            [action.payload.option]: newValue,
          },
        }
      } else {
        const { [action.payload.option]: thisValue, ...rest } =
          state.selectedFilters
        return {
          ...state,
          selectedFilters: rest,
        }
      }
    default:
      throw new Error()
  }
}

export default filtersReducer
