import React, { useContext } from "react"
import { LanguageContext } from "../../../contexts/Language"
import tw from "twin.macro"
import { Badge } from "../../atoms/Badge"

const FilterTitle: React.FC<{
  title: string
  isExpanded?: boolean
  isTransitioned?: boolean
  count: number
  showCount: boolean
}> = ({
  title,
  isExpanded = false,
  isTransitioned = false,
  count,
  showCount,
}) => {
  const { _ } = useContext(LanguageContext)

  return (
    <h5 css={[tw`text-sm mt-0 font-semibold inline uppercase mb-1`]}>
      {_(title)}
      {showCount && (
        <Badge color={"toyotaRed"} css={[tw` ml-3 rounded-full text-sm`]}>
          {`${count}`}
        </Badge>
      )}
    </h5>
  )
}

export default FilterTitle
