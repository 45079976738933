import React from "react"
import tw from "twin.macro"
import DealerFilter from "./DealerFilter"
import DistanceFilter from "./LocationFilter"
import PriceFilter from "./PriceFilter"
import AvailabilityFilter from "./AvailabilityFilter"

const StaticFilters: React.FC<{ sortedFilters: any[] }> = ({
  sortedFilters,
}) => {
  return (
    <>
      <div
        css={[
          tw`col-span-2  mt-5 lg:(col-span-1 gap-3 flex flex-col mr-2 mt-0)`,
        ]}
      >
        {" "}
        <DistanceFilter />
        <DealerFilter />
      </div>
      {sortedFilters && (
        <div
          css={[
            tw`col-span-2 lg:col-span-1 flex flex-col gap-5 lg:(row-span-2 mt-5)`,
          ]}
        >
          <div
            css={[
              tw`bg-gray-50 rounded-[10px] p-4 gap-4 md:(p-8)flex flex-col gap-8`,
            ]}
          >
            <PriceFilter {...sortedFilters[0]} isExpanded={true} />
            <AvailabilityFilter sortedFilter={sortedFilters[1]} />
          </div>
        </div>
      )}
    </>
  )
}
export default StaticFilters
