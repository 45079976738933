import React, { FocusEvent, ChangeEvent } from "react"
import tw from "twin.macro"

const Field: React.FC<{
  id?: string
  value?: string
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  tabIndex?: number
  name?: string
}> = ({ ...props }) => {
  return (
    <input
      type="text"
      maxLength={8}
      css={[
        tw`border mt-0.5 w-[95px] h-[41px] border-gray-200 text-center text-sm font-semibold`,
        tw`focus-visible:(outline-gray)`,
      ]}
      onKeyDown={(e: any) => {
        /* Prevent non-numerical keys from being pressed */
        if ((e.keyCode < 48 && !9) || e.keyCode > 57) {
          e.preventDefault()
        }
        /* Trigger blur when enter key is pressed */
        if (e.keyCode === 13) e.target.blur()
      }}
      {...props}
    />
  )
}

export default Field
