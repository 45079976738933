import React, { ChangeEvent, MouseEvent } from "react"
import tw from "twin.macro"

const Handle: React.FC<{
  min: number
  max: number
  value: number
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onClick?: (event: MouseEvent<HTMLInputElement>) => void
  tabIndex?: number
  name?: string
  analyticsId?: string
}> = ({ ...props }) => {
  return (
    <input
      type="range"
      tabIndex={-1}
      css={[
        tw`absolute h-0 w-[200px] outline-none pointer-events-none z-30`,
        "-webkit-appearance: none; -webkit-tap-highlight-color: transparent;",
        `&::-webkit-slider-thumb {
          -webkit-appearance: none;
          background-color: #c00000;
          border: none;
          border-radius: 10px;
          cursor: pointer;
          height: 24px;
          width: 12px;
          pointer-events: all;
          position: relative;
          box-shadow: 0 6px 6px -1px rgba(10, 22, 70, 0.1),
            0 0 1px 0 rgba(10, 22, 70, 0.06);
        }`,
      ]}
      {...props}
    />
  )
}

export default Handle
