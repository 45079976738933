import React, { useState, ChangeEvent, useContext, useEffect } from "react"
import tw from "twin.macro"
import { RangeProps } from "./Range.d"
import Field from "./Field"
import Handle from "./Handle"
import { LanguageContext } from "../../../contexts/Language"

const Range: React.FC<RangeProps> = ({
  label,
  inputs,
  min,
  max,
  minValue,
  maxValue,
  tabIndex,
  onChange,
  ...remainingProps
}) => {
  /* Input state */
  const [minVal, setMinVal] = useState(minValue ?? min)
  const [maxVal, setMaxVal] = useState(maxValue ?? max)
  const { _ } = useContext(LanguageContext)
  useEffect(() => {
    setMinVal(minValue ?? min)
    setMaxVal(maxValue ?? max)
  }, [maxValue, max, minValue, min])
  /* Convert to string with dollar sign and commas */
  const toUsd = (x: number) => {
    return `$${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
  }

  /* Convert back to number without dollar sign and commas */
  const fromUsd = (x: string) => {
    return Number(x.split("$").pop().replace(",", ""))
  }

  /* Handle input field change */
  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<number>>
  ) => {
    setState(fromUsd(e.target.value))
    onChange(e)
  }

  return (
    <article css={["width: fit-content;"]} {...remainingProps}>
      {label && <div css={[tw`text-center text-lg mb-5`]}>{label}</div>}
      {/* MIN AND MAX FIELDS */}
      {inputs && (
        <div css={[tw`w-[200px] flex justify-between`]}>
          {/* Min */}
          <div css={[tw`flex flex-col text-black text-center text-xs`]}>
            <label htmlFor="minField">{_("MIN")}</label>
            <Field
              id="minField"
              value={toUsd(minVal)}
              onBlur={() => {
                setMinVal(Math.min(minVal, maxVal - 1))
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(e, setMinVal)
              }
              tabIndex={tabIndex}
              name="min"
              analytics-id="price filter text:refinement:min"
            />
          </div>
          {/* Max */}
          <div css={[tw`flex flex-col text-black text-center text-xs`]}>
            <label htmlFor="maxField">{_("MAX")}</label>
            <Field
              id="maxField"
              value={toUsd(maxVal)}
              onBlur={() => {
                setMaxVal(Math.max(maxVal, minVal + 1))
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(e, setMaxVal)
              }
              tabIndex={tabIndex}
              name="max"
              analytics-id="price filter text:refinement:max"
            />
          </div>
        </div>
      )}
      {/* SLIDER */}
      <div css={[tw`mt-8 relative`]}>
        {/* Left handle */}
        <Handle
          min={min}
          max={max}
          value={minVal}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const value = Math.min(Number(event.target.value), maxVal - 1)
            setMinVal(value)
            onChange(event)
          }}
          css={[minVal > max - 100 && tw`z-[1]`]}
          tabIndex={tabIndex}
          name="min"
          analytics-id="price filter:refinement:min"
        />
        {/* Right handle */}
        <Handle
          min={min}
          max={max}
          value={maxVal}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const value = Math.max(Number(event.target.value), minVal + 1)
            setMaxVal(value)
            onChange(event)
          }}
          tabIndex={tabIndex}
          name="max"
          analytics-id="price filter:refinement:max"
        />
        {/* Track */}
        <div css={[tw`relative w-[200px]`]}>
          <div css={[tw`rounded h-[2px] absolute bg-gray-200 w-full`]}>
            <div
              css={[
                tw`absolute top-0 h-[2px] bg-red-400 z-[1]`,
                `width: ${((maxVal - minVal) / (max - min)) * 100}%;
                left: ${((minVal - min) / (max - min)) * 100}%;`,
              ]}
            />
          </div>
        </div>
      </div>
    </article>
  )
}

export default Range
