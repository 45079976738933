import React from "react"
import tw from "twin.macro"

const AnimatedIcon: React.FC<{ isExpanded: boolean }> = ({ isExpanded }) => {
  return (
    <div css={[tw`text-red-400 font-book text-3xl relative inline h-12`]}>
      <span
        css={[
          tw`absolute block w-12 h-10 top-[1.75px] bottom-0 right-0 text-center transition-all	duration-500	ease-in-out	box-content	`,
          isExpanded ? tw`opacity-100 rotate-0` : tw`opacity-0 rotate-90`,
        ]}
      >
        -
      </span>
      <span
        css={[
          tw`absolute block w-10 h-10 top-0 bottom-0 right-0 text-center transition-all	duration-500	ease-in-out box-content	p-1	`,
          isExpanded ? tw`opacity-0 rotate-0` : tw`opacity-100 rotate-90`,
        ]}
      >
        +
      </span>
    </div>
  )
}

export default AnimatedIcon
