import { css } from "@emotion/react"
import React, { useContext } from "react"
import tw from "twin.macro"
import { Checkbox } from "../../../components/atoms/Checkbox"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import {
  addSelectedFilter,
  removeSelectedFilter,
} from "../../../contexts/Filters/actions"
import { FiltersContext } from "../../../contexts/Filters/context"
import { useTealiumContext } from "../../../contexts/Tealium"
import { parseDisclaimerBlocks } from "../../../helpers"
import { CheckboxFilterProps } from "./CheckboxFilter.d"

const CheckboxFilterWithSuboptions: React.FC<CheckboxFilterProps> = ({
  value,
  paramName,
  isExpanded,
  index,
}) => {
  const [_disclaimerState, modalDispatch] = useContext(DisclaimersContext)
  const [filtersState, dispatchFilters] = useContext(FiltersContext)

  const label = value.description || value.name || value.title

  if (
    value.models[0].accessoryCodes &&
    value.models[0].accessoryCodes.startsWith("XX")
  ) {
    paramName = "accessoryModels"
    value.models = value.models.map(model => ({
      ...model,
      value: model.accessoryCodes,
    }))
  }

  const groupModelValues: string[] = value.models
    // .filter((model: any) => model.active)
    .map((model: any) => model.value)
    ?.flat()

  const groupIsChecked =
    groupModelValues.length &&
    filtersState.selectedFilters &&
    groupModelValues.every(r =>
      filtersState.selectedFilters[paramName]?.includes(r)
    )

  //Tealium
  const {
    tealPageData,
    addInventoryFilterToQueue,
    removeInventoryFilterFromQueue,
  } = useTealiumContext()
  return (
    <div
      css={[
        tw`flex flex-col gap-2 h-auto my-2`,
        css`
          page-break-inside: avoid;
          break-inside: avoid-column;
        `,
      ]}
    >
      <Checkbox
        label={parseDisclaimerBlocks(label, selection =>
          modalDispatch(toggleDisclaimersModal(selection))
        )}
        checked={groupIsChecked}
        onChange={() => {
          if (groupIsChecked) {
            dispatchFilters(removeSelectedFilter(paramName, groupModelValues))
            removeInventoryFilterFromQueue({
              refinement_value: `${tealPageData?.page_type}|${
                tealPageData?.page_name
              }|${
                paramName == "modelNumber" || paramName == "accessoryModels"
                  ? "Model"
                  : paramName
              }|${label}`,
            })
          } else {
            dispatchFilters(addSelectedFilter(paramName, groupModelValues))
            addInventoryFilterToQueue({
              refinement_value: `${tealPageData?.page_type}|${
                tealPageData?.page_name
              }|${
                paramName == "modelNumber" || paramName == "accessoryModels"
                  ? "Model"
                  : paramName
              }|${label}`,
            })
          }
        }}
        tabIndex={isExpanded ? 0 : -1}
        analyticsId={
          paramName
            ? `${
                paramName == "modelNumber" || paramName == "accessoryModels"
                  ? "model"
                  : paramName
              } filter:refinement:${index + 1}`
            : null
        }
      />
      <div css={[tw`pl-8 flex flex-col gap-2`]}>
        {value.models.sort().map((model: any, i: number) => {
          let checked: boolean
          if (Array.isArray(model.value)) {
            checked = model.value?.every(code =>
              filtersState.selectedFilters.accessoryModels?.includes(code)
            )
          } else {
            checked =
              filtersState.selectedFilters.hasOwnProperty(paramName) &&
              filtersState.selectedFilters[paramName].includes(model.value)
          }
          return (
            <Checkbox
              label={`${parseDisclaimerBlocks(
                model.description || model.name,
                selection => modalDispatch(toggleDisclaimersModal(selection))
              )} ${model.isHybrid ? "Hybrid" : ""}`}
              checked={checked}
              onChange={() => {
                if (checked) {
                  dispatchFilters(removeSelectedFilter(paramName, model.value))
                  removeInventoryFilterFromQueue({
                    refinement_value: `${tealPageData?.page_type}|${
                      tealPageData?.page_name
                    }|${
                      paramName == "modelNumber" ||
                      paramName == "accessoryModels"
                        ? "Model"
                        : paramName
                    }|${model.description}${model.isHybrid ? " Hybrid" : ""}`,
                  })
                } else {
                  dispatchFilters(addSelectedFilter(paramName, model.value))
                  addInventoryFilterToQueue({
                    refinement_value: `${tealPageData?.page_type}|${
                      tealPageData?.page_name
                    }|${
                      paramName == "modelNumber" ||
                      paramName == "accessoryModels"
                        ? "Model"
                        : paramName
                    }|${model.description}${model.isHybrid ? " Hybrid" : ""}`,
                  })
                }
              }}
              // disabled={!model.active}
              tabIndex={isExpanded ? 0 : -1}
              analyticsId={`${
                paramName == "modelNumber" || paramName == "accessoryModels"
                  ? "model"
                  : paramName
              } filter:refinement:${index + 1}|${i + 1}`}
            />
          )
        })}
      </div>
    </div>
  )
}

export default CheckboxFilterWithSuboptions
