import React, { useContext, useEffect, useRef, useState } from "react"
import AccessoryCheckbox from "./AccessoryCheckbox"
import { FiltersContext } from "../../../contexts/Filters/context"
import tw from "twin.macro"
import { AccessoryFilterProps, AccessoryFilterType } from "./AccessoryFilter.d"
import GenericRadio from "../../atoms/Radio/GenericRadio"
import {
  addSelectedFilter,
  removeSelectedFilter,
} from "../../../contexts/Filters/actions"
import { useTealiumContext } from "../../../contexts/Tealium"
import { stripDisclaimerCodes } from "../../../helpers"
import _ from "lodash"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { setDisclaimers } from "../../../contexts/Disclaimers/actions"
import { useDraggable } from "../../../hooks/useDraggable"
import AliceCarousel from "react-alice-carousel"
import Icon from "../../atoms/Icon"

const AccessoryFilter: React.FC<AccessoryFilterProps> = ({
  values,
  paramName,
}) => {
  const [disclaimersState, dispatchDisclaimers] = useContext(DisclaimersContext)
  const [filtersState, dispatchFilters] = useContext(FiltersContext)
  const parentContainer = useRef(null)
  const [items, setItems] = useState([])
  const [padding, setPadding] = useState(0)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    // Check the screen width and set padding for carousel
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setPadding(10)
      } else if (window.innerWidth <= 1024) {
        setPadding(20)
      } else {
        setPadding(0)
      }
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  //only add padding to carousel when disclaimer popover is active to avoid overlapping other filters
  useEffect(() => {
    const el = document.querySelector(
      ".accFilter .alice-carousel__wrapper"
    ) as HTMLElement
    if (!el) return
    if (disclaimersState.activeDisclaimerPopoverId) {
      el.style.paddingBottom = "650px"
      el.style.marginBottom = "-650px"
    } else {
      el.style.paddingBottom = "0px"
      el.style.marginBottom = "0px"
    }
  }, [disclaimersState.activeDisclaimerPopoverId])

  let categories = [...new Set(values.map(value => value.subCategory))]
    ?.filter(category => category)
    ?.sort()
  categories = categories.length == 1 ? categories : ["All", ...categories]
  const allAccessoriesSorted = values.sort((a, b) =>
    a.name.localeCompare(b.name)
  )

  const [selectedCategory, setSelectedCategory] = useState("All")
  const [filteredAccessories, setFilteredAccessories] =
    useState<AccessoryFilterType[]>(allAccessoriesSorted)
  // Update filteredAccessories when selectedCategory changes
  useEffect(() => {
    if (selectedCategory === "All") {
      setFilteredAccessories(allAccessoriesSorted)
    } else {
      setFilteredAccessories(
        values.filter(value => value.subCategory === selectedCategory)
      )
    }
  }, [selectedCategory, values])

  // Setting initial state
  useEffect(() => {
    const selectedFilters = filtersState.selectedFilters[paramName] || []
    const selectedAccessory = values?.find(accessory =>
      selectedFilters.includes(accessory.value)
    )
    const subCategory = selectedAccessory?.subCategory || categories[0]
    const accessories = values?.filter(
      accessory => accessory.subCategory === subCategory
    )

    if (
      filtersState.selectedFilters.accessoryModels?.length &&
      !selectedAccessory
    ) {
      setSelectedCategory("X-Series")
      setFilteredAccessories(
        accessories?.filter(
          accessory => accessory.subCategory === "X-Series"
        ) || []
      )
    } else {
      setSelectedCategory("All")
      setFilteredAccessories(allAccessoriesSorted)
    }
  }, [])

  useEffect(() => {
    setItems(
      filteredAccessories?.map(
        ({ id, value, name, media, subCategory, description }, index) => {
          const filterType =
            subCategory === "X-Series" ? "accessoryModels" : paramName
          const parentPosition = getParentPosition(parentContainer)

          return (
            <AccessoryCheckbox
              key={id}
              title={name}
              value={value}
              description={description}
              checked={filtersState.selectedFilters[filterType]?.includes(
                value
              )}
              imageSrc={media.asset?.url}
              handleCheck={(val: string) => {
                if (!isDragging) {
                  dispatchFilters(addSelectedFilter(filterType, val))
                  // Tagging
                  addInventoryFilterToQueue({
                    refinement_value: `${tealPageData?.page_type}|${
                      tealPageData?.page_name
                    }|Accessory|${stripDisclaimerCodes(name)}`,
                  })
                }
              }}
              handleUncheck={(val: string) => {
                dispatchFilters(removeSelectedFilter(filterType, val))

                // Tagging
                removeInventoryFilterFromQueue({
                  refinement_value: `${tealPageData?.page_type}|${
                    tealPageData?.page_name
                  }|Accessory|${stripDisclaimerCodes(name)}`,
                })
              }}
              parentLeft={parentPosition.left}
              parentRight={parentPosition.right}
              analytics-id={`accessory filter:refinement:${index + 1}`}
            />
          )
        }
      )
    )
  }, [filteredAccessories, filtersState.selectedFilters])
  const getParentPosition = (ref: any): { left: number; right: number } => {
    const parentContainerRect = ref?.current?.getBoundingClientRect()
    return {
      left: parentContainerRect?.left + 4,
      right: parentContainerRect?.right - 2,
    }
  }

  const responsive = {
    0: { items: 1 },
    768: { items: 2 },
  }
  const renderPrevButton = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute left-2 top-[21.5rem] z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gunmetal rounded-full transition-all hover:bg-gunmetalHover focus-visible:(bg-gunmetalHover)`,
          tw`lg:(-left-20  top-32)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
      >
        <Icon.Chevron direction="left" color="white" css={[tw`h-5`]} />
      </button>
    )
  }

  const renderNextButton = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute right-2 top-[21.5rem] z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gunmetal rounded-full transition-all hover:(bg-gunmetalHover) focus-visible:(bg-gunmetalHover)`,
          tw`lg:(-right-20 top-32)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
      >
        <Icon.Chevron direction="right" color="white" css={[tw`h-5`]} />
      </button>
    )
  }
  // Update filtered accessories when category changes
  useEffect(() => {
    const accessories =
      selectedCategory == "All"
        ? values
        : values?.filter(
            accessory => accessory.subCategory === selectedCategory
          )
    setFilteredAccessories(accessories)
    // force rerender to fix arrow buttons bug
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 100)
  }, [selectedCategory])

  // Add all accessory disclaimers to disclaimers context
  useEffect(() => {
    const accessoryDisclaimers = values?.flatMap(
      accessory => accessory.disclaimers
    )
    const newDisclaimers = _.unionBy(
      accessoryDisclaimers,
      disclaimersState.disclaimers,
      "code"
    )

    if (newDisclaimers.length !== disclaimersState.disclaimers.length) {
      dispatchDisclaimers(setDisclaimers(newDisclaimers))
    }
  }, [])

  //Drag functionality
  const {
    slider: slider1,
    mouseDownHandler: mouseDownHandler1,
    mouseLeaveHandler: mouseLeaveHandler1,
    mouseUpHandler: mouseUpHandler1,
    mouseMoveHandler: mouseMoveHandler1,
  } = useDraggable()

  const {
    slider: slider2,
    isDragging,
    mouseDownHandler: mouseDownHandler2,
    mouseLeaveHandler: mouseLeaveHandler2,
    mouseUpHandler: mouseUpHandler2,
    mouseMoveHandler: mouseMoveHandler2,
  } = useDraggable()
  //Tealium
  const {
    tealPageData,
    addInventoryFilterToQueue,
    removeInventoryFilterFromQueue,
  } = useTealiumContext()

  return (
    <div ref={parentContainer}>
      <div
        css={tw`overflow-x-scroll scrollbar-hide w-full`}
        ref={slider1}
        onMouseDown={mouseDownHandler1}
        onMouseLeave={mouseLeaveHandler1}
        onMouseUp={mouseUpHandler1}
        onMouseMove={mouseMoveHandler1}
      >
        {categories?.length > 1 && (
          <fieldset css={tw`flex gap-4`}>
            {categories?.map(category => {
              return (
                <GenericRadio
                  key={category}
                  label={category}
                  value={category}
                  name="accessoryFilterCategory"
                  handleChange={(value: string): void => {
                    setSelectedCategory(value)
                  }}
                  selectedStyles={tw`bg-black text-white`}
                  defaultChecked={category === selectedCategory}
                  css={[
                    tw`py-1.5 px-6 rounded-full border border-black whitespace-nowrap`,
                  ]}
                />
              )
            })}
          </fieldset>
        )}
      </div>
      <div css={[tw`max-w-[650px] mx-auto lg:(mx-20 max-w-[600px]) `]}>
        <div css={[tw`flex flex-nowrap h-full`]} className="accFilter">
          {!loading && (
            <AliceCarousel
              items={items}
              syncStateOnPropsUpdate={true}
              disableDotsControls
              mouseTracking
              responsive={responsive}
              paddingLeft={padding}
              paddingRight={padding}
              controlsStrategy="responsive"
              renderPrevButton={renderPrevButton}
              renderNextButton={renderNextButton}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AccessoryFilter
