import { css } from "@emotion/react"
import { useContext, useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import {
  addSelectedFilter,
  removeSelectedFilter,
} from "../../../contexts/Filters/actions"
import { FiltersContext } from "../../../contexts/Filters/context"
import { useTealiumContext } from "../../../contexts/Tealium"
import { stripDisclaimerCodes } from "../../../helpers"
import { ColorSwatch } from "../../atoms/ColorSwatch"
import { DisclaimerPopover } from "../DisclaimerPopover"
import ColorSwatchTooltip from "./ColorSwatchTooltip"

const ColorSwatchInventory: React.FC<{
  disclaimerParentPosition: any
  tooltipParentPosition: { left: number | null; right: number | null }
  value: any
  index: number
  paramName: string
  parentContainer?: any
}> = ({
  disclaimerParentPosition,
  tooltipParentPosition,
  value,
  index,
  paramName,
  parentContainer,
}) => {
  const [active, setActive] = useState(false)
  const [showName, setShowName] = useState(false)
  const swatchContainer = useRef(null)
  const [filtersState, dispatchFilters] = useContext(FiltersContext)
  const [currentHeight, setCurrentHeight] = useState(0)

  const {
    tealPageData,
    addInventoryFilterToQueue,
    removeInventoryFilterFromQueue,
    inventoryFilters,
  } = useTealiumContext()

  let checked =
    filtersState.selectedFilters[paramName]?.includes(value.value) ||
    filtersState.selectedFilters[paramName]?.includes(value.value[0])
  const extractedDisclaimer = value.name.substring(
    value.name.indexOf("[") + 1,
    value.name.lastIndexOf("]")
  )

  useEffect(() => {
    if (swatchContainer.current) {
      setCurrentHeight(swatchContainer.current.clientHeight)
    }
    swatchContainer?.current?.addEventListener("mouseenter", () => {
      setActive(true)
    })
    swatchContainer?.current?.addEventListener("mouseleave", () => {
      setActive(false)
      setShowName(false)
    })

    return () => {
      swatchContainer?.current?.removeEventListener("mouseenter")
      swatchContainer?.current?.removeEventListener("mouseleave")
    }
  }, [])
  return (
    <div
      css={[
        tw`relative min-h-0  xl:min-h-[144px]`,
        showName && tw`min-h-[144px]`,
        currentHeight !== 144 && tw`transition-all duration-500 ease-in-out`,
      ]}
      key={index}
      className="group"
      ref={swatchContainer}
    >
      {extractedDisclaimer.length > 0 && disclaimerParentPosition && (
        <div css={[tw`absolute top-0 right-[-16px]`]}>
          <DisclaimerPopover
            onClick={() => toggleDisclaimersModal(extractedDisclaimer)}
            code={extractedDisclaimer}
            parentLeft={disclaimerParentPosition.left}
            parentRight={disclaimerParentPosition.right}
            width={250}
          />
        </div>
      )}
      <ColorSwatch
        name={value.name}
        color={value.hex}
        selected={checked}
        fromModal
        backgroundImageUrl={
          paramName == "interiorColor" ? value.swatchCropped : null
        }
        css={[
          tw`rounded-full w-12 h-12`,
          css`
            &:after {
              content: "";
              ${tw`rounded-full`}
            }
          `,
        ]}
        onTouchStart={(e: any) => {
          e.preventDefault()
          const element = document.getElementById("swatchDiv")
          element.scrollBy(0, 100)
        }}
        onClick={() => {
          if (checked) {
            setShowName(false)
            dispatchFilters(
              removeSelectedFilter(
                paramName,
                paramName == "interiorColor" ? value.value[0] : value.value
              )
            )
            removeInventoryFilterFromQueue({
              refinement_value: `${tealPageData?.page_type}|${
                tealPageData?.page_name
              }|${
                paramName == "exteriorColor"
                  ? "Exterior Color"
                  : paramName == "interiorColor"
                  ? "Interior Color"
                  : paramName
              }|${stripDisclaimerCodes(value.name)}`,
            })
          } else {
            setShowName(true)
            dispatchFilters(
              addSelectedFilter(
                paramName,
                paramName == "interiorColor" ? value.value[0] : value.value
              )
            )
            addInventoryFilterToQueue({
              refinement_value: `${tealPageData?.page_type}|${
                tealPageData?.page_name
              }|${
                paramName == "exteriorColor"
                  ? "Exterior Color"
                  : paramName == "interiorColor"
                  ? "Interior Color"
                  : paramName
              }|${stripDisclaimerCodes(value.name)}`,
            })
          }
        }}
        analytics-id={
          paramName
            ? `${
                paramName == "exteriorColor"
                  ? "exterior color"
                  : paramName == "interiorColor"
                  ? "interior color"
                  : paramName
              } filter:refinement:${index + 1}`
            : null
        }
        paramName={paramName}
      />
      <div css={[tw`hidden`, showName && tw`flex absolute z-50 top-16 left-0`]}>
        <ColorSwatchTooltip
          parentLeft={tooltipParentPosition.left}
          parentRight={tooltipParentPosition.right}
          swatchContainer={swatchContainer}
          showName={showName}
        >
          {stripDisclaimerCodes(value.name)}
        </ColorSwatchTooltip>
      </div>
    </div>
  )
}

export default ColorSwatchInventory
