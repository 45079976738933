import { Buffer } from "buffer"
import React, { useContext, useEffect, useState } from "react"
import { useInfiniteQuery, useQuery } from "react-query"
import tw from "twin.macro"
import { FiltersClient } from "../../../clients/FiltersClient"
import { InventoryClient } from "../../../clients/InventoryClient"
import { setZip } from "../../../contexts/Location/actions"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"
import {
  closeFiltersModal,
  openFiltersModal,
  updateDealersFilter,
  updateSelectedFilters,
} from "../../../contexts/Filters/actions"
import { FiltersContext } from "../../../contexts/Filters/context"
import { setDealer, setSortBy } from "../../../contexts/Inventory/actions"
import { InventoryContext } from "../../../contexts/Inventory/context"
import { LanguageContext } from "../../../contexts/Language"
import { LocationContext } from "../../../contexts/Location"
import {
  setRadius,
  setSelectedDealer,
} from "../../../contexts/Location/actions"
import { useTealiumContext } from "../../../contexts/Tealium"
import {
  defaultRadiusOptions,
  normalizeSeriesNameForLink,
  remove4by,
  sanitizeSeries,
  vehicleCategory,
} from "../../../helpers"
import useRefinement from "../../../hooks/Tealium/useRefinement"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import useTealiumView from "../../../hooks/Tealium/useTealiumView"
import useDealers from "../../../hooks/useDealers"
import unpluggedGraphic from "../../../images/Unplugged.svg"
import { ButtonLink } from "../../atoms/Button"
import Icon, { Loader } from "../../atoms/Icon"
import { Option, Select } from "../../atoms/Select"
import Filters from "./Filters"
import InventoryResults from "./InventoryResults"
import { processVehiclesData } from "./TealiumHelpers"

const Inventory: React.FC<{
  series: string
  category: string
  name: string
}> = ({ series, category, name }) => {
  const [state, dispatch] = useContext(InventoryContext)
  const [{ radius, dealers, isOutOfRange }, locationDispatch] =
    useContext(LocationContext)
  const [filtersState, dispatchFilters] = useContext(FiltersContext)
  const [prevVehicles, setPrevVehicles] = useState(null)
  const [fetchedWithinMaxRadius, setFetchedWithinMaxRadius] = useState(false)
  const [zIndex, setZIndex] = useState(1) // Initial z-index value

  const handleMouseEnter = () => {
    setZIndex(11) // Set the new z-index value on mouse enter
  }

  const handleMouseLeave = () => {
    setZIndex(1) // Reset the z-index value on mouse leave
  }

  const { setInventoryResults, tealPageData, updateVehicleTealData } =
    useTealiumContext()
  const { language, _ } = useContext(LanguageContext)

  const { getDealerInfo } = useDealers()

  const sortOptions = [
    {
      label: _("Price: Low to High"),
      value: "price|ASC",
    },
    {
      label: _("Price: High to Low"),
      value: "price|DESC",
    },
    {
      label: _("Year: Low to High"),
      value: "year|ASC",
    },
    {
      label: _("Year: High to Low"),
      value: "year|DESC",
    },
  ]

  useEffect(() => {
    // update dealer filter in inventory state
    if (dealers && !filtersState.modalOpen) {
      // Checking if single dealer has been provided in query params
      let decodedQueryParams
      try {
        const encodedQueryParams = location?.search?.substring(3)
        const decodedQueryParamsString = Buffer.from(
          encodedQueryParams,
          "base64"
        )?.toString("utf8")
        decodedQueryParams =
          decodedQueryParamsString && JSON.parse(decodedQueryParamsString)
        if (decodedQueryParams.source) {
          LocalStorageClient.write("source", decodedQueryParams.source)
        }
        if (decodedQueryParams.sourceZip) {
          LocalStorageClient.write("confirmedZip", true)
          LocalStorageClient.write("sourceZip", decodedQueryParams.sourceZip)
        }
      } catch (err) {
        console.log("Error while parsing dealer from url params", err.message)
      }

      let dealersCodesFromQueryParams = decodedQueryParams?.dealer
      let dealerCodeFromQueryParams = null
      if (
        dealersCodesFromQueryParams &&
        typeof dealersCodesFromQueryParams === "string"
      ) {
        dealersCodesFromQueryParams = dealersCodesFromQueryParams?.split(",")
        dealerCodeFromQueryParams =
          dealersCodesFromQueryParams?.length === 1
            ? dealersCodesFromQueryParams[0]
            : null
        if (dealerCodeFromQueryParams) {
          dispatchFilters(updateDealersFilter(dealersCodesFromQueryParams))
          // Setting selected dealer to dealer provided via url params
          const selectedDealerCode = dealerCodeFromQueryParams
          if (state.dealer === selectedDealerCode) return
          dispatch(setDealer(selectedDealerCode))
          locationDispatch(setSelectedDealer(selectedDealerCode))
        }
      }

      // Update inventory state with all dealers if no single dealer is provided, or if there is no inventory with dealer filter
      if (!dealerCodeFromQueryParams && !filtersState.currentDealerFilter) {
        // If no single dealer is provided then use the location context to set dealers filters
        const allDealers = dealers.map(dealer => dealer.DealerCode).join(",")
        if (allDealers === state.dealer) return
        dispatch(setDealer(allDealers))
      }
    } else if (dealers && filtersState.modalOpen) {
      // If modal is open and the provided dealers changes, determine if the currentDealer should be removed from FiltersState
      const locationDealerCodes = dealers?.map(dealer => dealer.DealerCode)
      if (!locationDealerCodes.includes(filtersState?.currentDealerFilter)) {
        const { dealer, ...remainingFilters } = filtersState.selectedFilters
        //remove currentDealer from FiltersState
        dispatchFilters(updateDealersFilter(null))
        //remove dealer from selectedFilters in FiltersState
        const filtersForModal = remainingFilters
        dispatchFilters(updateSelectedFilters(filtersForModal))
        //update tagging queue
        removeInventoryFilterFromQueueByKey(
          `${tealPageData?.page_type}|${tealPageData?.page_name}|Dealer|`
        )
      }
    }
  }, [dealers])

  const filterQuery = useQuery(
    ["filters", state],
    async () => {
      if (!state) return

      return await FiltersClient.getBySeries(series, state)
    },
    {
      keepPreviousData: true,
    }
  )
  //calculate count for Filters button
  const filtersCount =
    (state?.year ? (Array.isArray(state?.year) ? state?.year?.length : 1) : 0) +
    (state?.modelNumber
      ? Array.isArray(state?.modelNumber)
        ? state?.modelNumber?.length
        : 1
      : 0) +
    (state?.priceMax ? 1 : 0) +
    (state?.priceMin ? 1 : 0) +
    (state?.exteriorColor ? state?.exteriorColor?.length : 0) +
    (state?.interiorColor ? state?.interiorColor?.length : 0) +
    (state?.available ? state?.available?.length : 0) +
    (filtersState.currentDealerFilter || filtersState.selectedFilters.dealer
      ? 1
      : 0) +
    (state?.accessoryFIAs ? state.accessoryFIAs?.length : 0) +
    (state?.accessoryPIOs ? state.accessoryPIOs?.length : 0) +
    (state?.accessoryModels ? state.accessoryModels.length + 1 : 0)

  // PLEASE NOTE: If this query is changed, please inform the Analytics dev team as it will affect tagging for Inventory
  const inventoryQuery = useInfiniteQuery(
    ["inventory", state],
    async ({ pageParam = 0 }) => {
      const inventory = await InventoryClient.getBySeries(series, {
        ...state,
        offset: pageParam,
        include: ["accessories"],
      })
      if (radius === 100) {
        setFetchedWithinMaxRadius(true)
      } else {
        setFetchedWithinMaxRadius(false)
      }
      if (inventory?.count === 0) {
        const nextRadius =
          defaultRadiusOptions[defaultRadiusOptions.indexOf(radius) + 1]
        if (nextRadius && !filtersState.currentDealerFilter) {
          locationDispatch(setRadius(nextRadius))
        }
      }

      return inventory
    },
    {
      getNextPageParam: lastPage => {
        if (lastPage?.count >= lastPage?.offset + state.limit) {
          return lastPage?.offset + state.limit
        }
      },
    }
  )
  const handleModalOpen = () => {
    const { sortBy, limit, dealer, ...remainingFilters } = state

    //Only send dealers filter to modal if the inventory is being filtered by a single dealer
    let filtersForModal
    if (filtersState.currentDealerFilter) {
      filtersForModal = { dealer, ...remainingFilters }
    } else {
      filtersForModal = remainingFilters
    }
    dispatchFilters(openFiltersModal())
    dispatchFilters(updateSelectedFilters(filtersForModal))
  }

  /* START of Tealium Logic */
  // NOTE: "Page view and refinement" events for Series Inventory is handled in the useRefinement custom hook

  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData, removeInventoryFilterFromQueueByKey } =
    useTealiumContext()
  const { handleTealView } = useTealiumView()
  // page_name uses lowercase series name, no spaces
  const seriesName_lc = sanitizeSeries(name)
  const seriesName = remove4by(name)
  // initialize useRefinement hook for series inventory page
  const inventoryInfo = {
    category: vehicleCategory(seriesName),
    seriesNameLc: seriesName_lc,
    seriesName,
    originalName: name,
    inventoryQuery,
  }

  useEffect(() => {
    !series &&
      handleTealView({
        page_type: "inventory srp",
        page_name: "srp_general",
        tealium_event: "srp_general",
      })
  }, [])

  // Initialize events and pagelaod that are dependent on inventory data
  series && useRefinement(inventoryInfo)
  // Listen to inventory query. Once it's "fetched", check if the value has been changed (it fires bunch of times)
  // If inventory is successfully fetched, then process the data and set it to context
  // useEffect with no dep. array is necessary to make sure setState occurs at correct time (i.e. after Inventory component renders)
  useEffect(() => {
    if (inventoryQuery.isFetched) {
      const resultsNum = inventoryQuery?.data?.pages[0]?.count
      //TO DO: no longer needed because duplicate api calls are gone!
      if (prevVehicles !== resultsNum) {
        setPrevVehicles(resultsNum)
        const processedData = processVehiclesData(
          inventoryQuery?.data?.pages[0]?.vehicles,
          resultsNum
        )
        setInventoryResults(processedData)

        // "Each time inventory is successfully updated, update the context value for vehicleDatam for Tealium"
        const {
          vehicle_exterior_color,
          vehicle_interior_color,
          vehicle_model,
          vehicle_sale_price,
          vehicle_trim,
          vehicle_trim_id,
          vehicle_vin,
          vehicle_year,
        } = processedData
        // updates (not overwrites) vehicle data
        updateVehicleTealData({
          vehicle_exterior_color,
          vehicle_interior_color,
          vehicle_model,
          vehicle_sale_price,
          vehicle_trim,
          vehicle_trim_id,
          vehicle_vin,
          vehicle_year,
        })
      }
    }
  })

  // "Sorting" refinement events do not depend on asynchronous data; they sort data that has already loaded.

  // END - Variables for sorting events

  // Action specifically for the "Sort By" refinement option
  const trackSortRefinement = (refinementValue = "") => {
    const dealerInfo = getDealerInfo()
    trackTealEvent({
      tealium_event: "refinement",
      allocated_dealer_code: dealerInfo?.dealerCodes,
      allocated_dealer_name: dealerInfo?.dealerNames,
      vehicle_model_truncated: seriesName,
      refinement_value: refinementValue,
      search_results: inventoryQuery?.data?.pages[0]?.count || 0,
      ...vehicleTealData,
    })
  }
  /* END of Tealium logic */

  if (inventoryQuery.status === "error")
    return (
      <>
        <div css={[tw`text-center mx-8 my-20`, tw`md:(mx-auto max-w-[460px])`]}>
          <p css={[tw`text-toyotaRed uppercase pb-4 font-semibold`]}>
            {_("500 Error")}
          </p>
          <p css={[tw`text-3xl font-light`, tw`md:(text-4xl)`]}>
            {_("Oops! Technical Disconnect Detected.")}
          </p>
          <p css={[tw`pt-4 pb-6`]}>
            {_(
              "Oops! We encountered a temporary setback on our server. Rest assured, this is just a brief detour on your journey. We're committed to getting you back on track promptly."
            )}
          </p>
          <ButtonLink
            primary
            to={`/offers/${normalizeSeriesNameForLink(seriesName)}`}
            onClick={() => {
              trackTealEvent({
                page_name: "srp api error",
                vehicle_model: seriesName,
                vehicle_model_truncated: seriesName,
                coupon_module_text: `Explore ${series} Offers`,
                link_href: `/offers/${normalizeSeriesNameForLink(seriesName)}`,
                tealium_event: "cta_click",
              })
            }}
            analytics-id={`series offers:error message:`}
          >
            {`${_("Explore")} ${series} ${_("Offers")}`}
          </ButtonLink>
          <div css={[tw`flex justify-center my-4`]}>
            <img src={unpluggedGraphic} />
          </div>
        </div>
      </>
    )
  return (
    <>
      <div css={[tw`px-8`]}>
        <div css={[tw`border-b w-full my-4 my-4 lg:(my-16)`]}>
          <div
            css={[
              tw`max-w-[1190px] flex flex-wrap justify-center gap-5 items-center mx-auto mb-4`,
            ]}
          >
            <button
              disabled={
                !series || filterQuery.status == "loading" ? true : false
              }
              css={[
                tw`bg-black rounded-lg w-auto h-auto mt-0 flex items-center p-3 text-sm font-semibold text-white disabled:(bg-gray-500 pointer-events-none)`,
              ]}
              onClick={handleModalOpen}
            >
              <Icon.Filter color="white" css={[tw`h-5`]} />
              <p css={[tw`ml-4 mr-2`]}>{`${_("Filters")}`}</p>
              {filterQuery.status == "loading" ? (
                <Loader color={"white"} css={[tw`h-6 block animate-spin`]} />
              ) : (
                `(${filtersCount})`
              )}
            </button>
            <div>
              {inventoryQuery.status !== "loading" && series && (
                <span>
                  <span css={[tw`font-semibold`]}>
                    {inventoryQuery?.data?.pages[0]?.count}
                  </span>{" "}
                  {_("Exact Matches")}
                </span>
              )}
              {!series && <span>{_("Select a Series to get started")}</span>}
            </div>
            <div aria-label="Sort Inventory" css={[tw` md:block`]}>
              <span css={[tw`font-semibold pr-4`]}>{_("Sort By")}</span>
              <Select
                red
                css={[
                  tw`normal-case text-base mx-2 bg-white border-b border-black`,
                ]}
                value={[
                  `${state.sortBy}`,
                  `${
                    sortOptions.find(option => option.value === state.sortBy)
                      ?.label || _("Choose an option")
                  }`,
                ]}
                onChange={([value, label]) => {
                  dispatch(setSortBy(value))
                  trackSortRefinement(
                    `${tealPageData?.page_type}|${tealPageData?.page_name}|Sort By|${label}`
                  )
                }}
              >
                {sortOptions?.map(({ label, value }, index) => (
                  <Option
                    value={value}
                    label={label}
                    analytics-id={`sort by:refinement:${index + 1}`}
                  />
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
      <Filters
        filters={filterQuery.data?.filters}
        isLoading={filterQuery.status === "loading"}
        modalOpen={filtersState.modalOpen}
        closeModal={() => dispatchFilters(closeFiltersModal())}
      />

      {series && (
        <h1
          css={[
            tw`text-center font-light text-2xl mb-8 text-gray-700 tracking-[8.75px]`,
            tw`lg:(text-4xl mb-20)`,
          ]}
        >
          {language === "en" && (
            <>
              {_("Local Toyota")}
              <br />
              {seriesName} {_("Inventory")}
            </>
          )}
          {language === "es" && (
            <>
              {_("Inventory ")}
              <br />
              {seriesName} {_("Local Toyota")}
            </>
          )}
        </h1>
      )}
      <div
        css={[
          tw`grid grid-cols-1 px-0`,
          tw`md:(grid grid-cols-1 px-24)`,
          tw`lg:(grid grid-cols-4 gap-8 px-8)`,
          "z-index: 1; position: relative",
        ]}
      >
        <section
          aria-label="Inventory Results Section"
          css={[tw`col-span-1`, tw`md:(col-span-8)`, tw`lg:(col-span-4)`]}
          className="your-element"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ zIndex }}
        >
          {series ? (
            <InventoryResults
              inventoryQuery={inventoryQuery}
              isLoading={
                inventoryQuery.status === "loading" ||
                inventoryQuery.isFetchingNextPage
              }
              handleLoadMore={() => inventoryQuery.fetchNextPage()}
              showNoResultsNotice={
                inventoryQuery.status != "loading" &&
                !inventoryQuery.isFetchingNextPage &&
                (fetchedWithinMaxRadius ||
                  filtersState.currentDealerFilter !== null)
              }
              seriesFilters={filterQuery.data?.filters}
              series={series}
            />
          ) : (
            <div
              css={[
                tw`max-w-lg mx-auto px-8 mt-8 mb-12`,
                tw`md:(pt-8)`,
                tw`lg:(pt-12)`,
              ]}
            >
              <p css={tw`text-center`}>
                {_(
                  "To get started select a segment, like Crossovers & SUVs or Trucks, that you're looking for. Once you select a segment, you'll be able to select a series and view the inventory near you."
                )}
              </p>
            </div>
          )}
        </section>
      </div>
    </>
  )
}

export default Inventory
