export enum ACTION_TYPES {
  TOGGLE_FILTERS_DRAWER,
  OPEN_FILTERS_MODAL,
  CLOSE_FILTERS_MODAL,
  CLEAR_SELECTED_FILTERS,
  UPDATE_SELECTED_FILTERS,
  ADD_SELECTED_FILTERS,
  SET_SELECTED_FILTER_OPTION,
  REMOVE_SELECTED_FILTERS,
  UPDATE_DEALERS_FILTER,
}

export const toggleFiltersDrawer = () => ({
  type: ACTION_TYPES.TOGGLE_FILTERS_DRAWER,
})

export const openFiltersModal = () => ({
  type: ACTION_TYPES.OPEN_FILTERS_MODAL,
})
export const closeFiltersModal = () => ({
  type: ACTION_TYPES.CLOSE_FILTERS_MODAL,
})
export const updateDealersFilter = (value: string) => ({
  type: ACTION_TYPES.UPDATE_DEALERS_FILTER,
  payload: value,
})
export const clearFiltersModal = () => ({
  type: ACTION_TYPES.CLEAR_SELECTED_FILTERS,
})

export const updateSelectedFilters = (filters: { [t: string]: any }) => ({
  type: ACTION_TYPES.UPDATE_SELECTED_FILTERS,
  payload: filters,
})
export const addSelectedFilter = (
  option: string,
  value: string | number | string[] | number[] | null
) => ({
  type: ACTION_TYPES.ADD_SELECTED_FILTERS,
  payload: {
    option,
    value,
  },
})
export const setSelectedFilterOption = (
  option: string,
  value: string | number
) => ({
  type: ACTION_TYPES.SET_SELECTED_FILTER_OPTION,
  payload: {
    option,
    value,
  },
})
export const removeSelectedFilter = (
  option: string,
  value: string | number | string[] | number[] | null
) => ({
  type: ACTION_TYPES.REMOVE_SELECTED_FILTERS,
  payload: {
    option,
    value,
  },
})
