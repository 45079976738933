import React, { useContext, useEffect } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { Layout } from "../components/atoms/Layout"
import { PageProps } from "gatsby"
import { InventoryProvider } from "../contexts/Inventory/context"
import Inventory from "../components/organisms/Inventory"
import { ReactQueryDevtools } from "react-query/devtools"
import { FamilySeriesProvider } from "../contexts/FamilySeries/context"
import FamilySeries from "../components/organisms/Inventory/FamilySeries"
import { FiltersProvider } from "../contexts/Filters/context"
import { ConfirmZipContext } from "../contexts/ConfirmZip"
import { openConfirmZipModal } from "../contexts/ConfirmZip/actions"
import { LocalStorageClient } from "../clients/LocalStorageClient"
import { SEO } from "../components/atoms/SEO"
import { ConfirmZipModal } from "../components/molecules/ConfirmZipModal"

const queryClient = new QueryClient()

const InventoryPage: React.FC<PageProps> = ({ pageContext, location }: any) => {
  // Show Confirm Zip Modal if zip has not been confirmed
  const [{}, dispatchConfirmZip] = useContext(ConfirmZipContext)
  useEffect(() => {
    const zipIsConfirmed = LocalStorageClient.read("confirmedZip")
    if (zipIsConfirmed != true) {
      dispatchConfirmZip(openConfirmZipModal())
    }
  }, [])
  const customSchema = `{
    {

      "@context": "[http://schema.org](http://schema.org/)",
      
      "@type": "AutomotiveBusiness",
      
      "name": "${
        pageContext.name !== ""
          ? `Southeast Toyota Dealers ${pageContext.name} Inventory`
          : `Southeast Toyota Dealers Inventory`
      }",
      
      "description": "Select a Toyota Series of Vehicles to see your local inventory available in AL, FL, GA, SC, & NC.",
      
      "logo": "https://cdn.sanity.io/images/90qxor39/production/cd0d47fee6c6d265d584179267e02dc77fb966b7-900x350.svg",
      
      "url": "${location.href}",
      
      "sameAs": ["[https://x.com/ExploreSEToyota","https://www.facebook.com/exploresetoyota/","https://www.youtube.com/c/LocalToyotaDealers","https://www.instagram.com/exploresetoyota/","https://en.wikipedia.org/wiki/Southeast_Toyota_Distributors","https://www.pinterest.com/ExploreSEToyota/](https://x.com/ExploreSEToyota%22,%22https://www.facebook.com/exploresetoyota/%22,%22https://www.youtube.com/c/LocalToyotaDealers%22,%22https://www.instagram.com/exploresetoyota/%22,%22https://en.wikipedia.org/wiki/Southeast_Toyota_Distributors%22,%22https://www.pinterest.com/ExploreSEToyota/)"]
      
      }
  }`

  return (
    <Layout tealium={{ label: "inventoryPage" }}>
      <SEO
        url={location.href}
        title={`Toyota ${pageContext?.name ? pageContext.name : ""} Inventory`}
        schema={customSchema}
      />
      <ConfirmZipModal />
      <InventoryProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={true} />
          <FamilySeriesProvider>
            <FamilySeries series={pageContext.slug} />
          </FamilySeriesProvider>
          <FiltersProvider>
            <Inventory
              series={pageContext.name}
              category={pageContext.category}
              name={pageContext.name}
            />
          </FiltersProvider>
        </QueryClientProvider>
      </InventoryProvider>
    </Layout>
  )
}

export default InventoryPage
