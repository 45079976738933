import React, { useContext, useEffect } from "react"
import tw from "twin.macro"
import { Filter } from "../../../clients/FiltersClient.d"
import { ContactContext } from "../../../contexts/Contact"
import {
  openContactDealerModal,
  updateField,
} from "../../../contexts/Contact/actions"
import { InventoryContext } from "../../../contexts/Inventory/context"
import { LanguageContext } from "../../../contexts/Language"
import { LocationContext } from "../../../contexts/Location"
import { setRadius } from "../../../contexts/Location/actions"
import {
  toUsd,
  getModelNamesByModelNumbers,
  getModelNamesByAccessoryModelCodes,
  stripDisclaimerCodes,
} from "../../../helpers"
import { Button } from "../../atoms/Button"

/**
 * No More Inventory Card
 *
 * Handle logic for showing no more exact matches on search results page
 *
 * @returns JSX
 */
const NoMoreInventoryCard: React.FC<{
  seriesFilters: Filter[]
  seriesName: string
}> = ({ seriesFilters, seriesName }) => {
  const [state, dispatchContact] = useContext(ContactContext)
  const [{ radius }, dispatchLocation] = useContext(LocationContext)
  const { _ } = useContext(LanguageContext)
  const radiusOptions = [25, 50, 100]
  const nextRadius =
    radiusOptions[radiusOptions.findIndex(option => option == radius) + 1]

  const [filters] = useContext(InventoryContext)
  const [selectedFilters, setSelectedFilters] = React.useState([])

  // Get the formatted filter values to be displayed to the user
  const getFilterValueLabel = (key: string, value: any) => {
    const paramName = key === "accessoryModels" ? "modelNumber" : key
    const filter = seriesFilters?.find(
      (filter: Filter) => filter.paramName === paramName
    )
    let displayValue = ""

    // Formatting price to USD
    if (key == "priceMax" || key == "priceMin") {
      const price = Number(value)
      displayValue = `$${toUsd(price)}`
      return displayValue
    }

    // Return formatted value if filter does not exist in seriesFilters
    if (!filter && Array.isArray(value)) {
      return filters[key].join(", ")
    } else if (!filter) {
      const formattedValue =
        filters[key].charAt(0).toUpperCase() + filters[key].slice(1)
      return formattedValue
    }

    // Swap modelNumbers for modelNames
    if (key === "modelNumber") {
      const modelNames = getModelNamesByModelNumbers(filter, value)
      displayValue = modelNames?.join(", ")
      return displayValue
    }

    // Swap accessoryModels for modelNames
    if (key === "accessoryModels") {
      const customModelNames = getModelNamesByAccessoryModelCodes(filter, value)
      displayValue = customModelNames?.join(", ")
      return displayValue
    }

    // Default formatting
    if (!Array.isArray(value)) {
      displayValue = filter?.values?.find(
        (val: any) => val.value == value
      )?.name
    } else {
      value.forEach((filterValue, i) => {
        const selectValue = filter?.values?.find(
          (item: any) => item.value === filterValue
        )?.name
        if (selectValue) {
          const label = `${selectValue}${i !== value.length - 1 ? ", " : ""}`
          displayValue += label
        }
      })
    }

    return stripDisclaimerCodes(displayValue)
  }

  useEffect(() => {
    let filtersArray = Object.keys(filters)
      .filter(
        key =>
          ![
            "dealer",
            "limit",
            "sortBy",
            "isHybridFamilySelected",
            "radius",
          ].includes(key)
      )
      .map(key => {
        let value = getFilterValueLabel(key, filters[key])
        return {
          key: key,
          value: value,
        }
      })
    // Combining modelNumbers and accessoryModels into Models
    const modelNumbers = filtersArray.find(
      obj => obj.key === "modelNumber"
    )?.value
    const accessoryModels = filtersArray.find(
      obj => obj.key === "accessoryModels"
    )?.value
    const modelNames = `${modelNumbers ? modelNumbers : ""}${
      modelNumbers && accessoryModels ? ", " : ""
    }${accessoryModels ? accessoryModels : ""}`
    filtersArray = filtersArray.filter(
      obj => obj.key !== "modelNumber" && obj.key !== "accessoryModels"
    )
    if (modelNames) {
      filtersArray.push({ key: "models", value: modelNames })
    }
    // Setting series to seriesName argument if it's not already set.
    const filtersSeries = filtersArray.find(obj => obj.key === "series")
    if (!filtersSeries && seriesName) {
      filtersArray.unshift({ key: "series", value: seriesName })
    }

    setSelectedFilters(filtersArray)
  }, [filters])

  const handleContactDealer = () => {
    dispatchContact(
      openContactDealerModal(null, null, "New Inventory Availability")
    )
    // Setting form's optional comments to include selected filters
    if (selectedFilters?.length > 0) {
      let formattedFiltersString = ""
      selectedFilters.forEach((filter, i) => {
        const label = filter.key.replace(/([A-Z])/g, " $1")
        formattedFiltersString += `${
          label.charAt(0).toUpperCase() + label.slice(1)
        }: ${filter.value}${i !== selectedFilters?.length - 1 ? ", " : ""}`
      })
      let comment = _("I'm interested in the") + " " + formattedFiltersString
      dispatchContact(updateField("comments", comment))
      const selectedSeriesName =
        selectedFilters.find(obj => obj.key === "series")?.value || seriesName
      dispatchContact(updateField("seriesName", selectedSeriesName))
    }
  }

  return (
    <div css={[tw`bg-gray-100 rounded-lg p-16 mt-12 text-center`]}>
      <p css={[tw`font-bold text-lg`]}>
        {_(`Sorry, there are no more exact matches within`)} {radius}{" "}
        {_("miles of your ZIP code.")}
      </p>
      {!nextRadius && (
        <p>
          {_(
            "Refine your search or contact a dealer about this specific vehicle."
          )}
        </p>
      )}
      <div css={[tw`pt-6 flex justify-center gap-4`]}>
        {nextRadius && (
          <Button
            onClick={() => {
              dispatchLocation(setRadius(nextRadius))
            }}
            secondary
          >
            {_("Expand to")} {nextRadius} {"Miles"}
          </Button>
        )}
        <Button primary onClick={handleContactDealer}>
          {_("Contact a Dealer")}
        </Button>
      </div>
    </div>
  )
}

export default NoMoreInventoryCard
