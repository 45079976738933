import React, { useContext } from "react"

import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import { LocationSelector } from "../../molecules/LocationSelector"
import { RadiusSelector } from "../../molecules/RadiusSelector"

const Distance = () => {
  const { _ } = useContext(LanguageContext)
  return (
    <div css={[tw`flex flex-col gap-5 lg:(mt-5)`]}>
      <div>
        <h5 css={[tw`text-sm mt-0 font-semibold inline uppercase mb-1`]}>
          {_("Starting Zip Code")}
        </h5>
        <LocationSelector
          location="inventory"
          focusColor={"gray"}
          analyticsId="filter modal"
        />
      </div>
      <div>
        <h5 css={[tw`text-sm mt-0 font-semibold inline uppercase pb-4`]}>
          {_("Distance")}
        </h5>
        <RadiusSelector />
      </div>
    </div>
  )
}

export default Distance
