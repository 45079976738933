import React, { ChangeEvent, useContext, useEffect } from "react"
import { FiltersContext } from "../../../contexts/Filters/context"
import { useTealiumContext } from "../../../contexts/Tealium"
import {
  removeSelectedFilter,
  setSelectedFilterOption,
} from "../../../contexts/Filters/actions"
import { toUsd } from "../../../helpers"
import { Range } from "../../../components/atoms/Range"
import { Filter } from "../../../clients/FiltersClient.d"
import FilterTitle from "./FilterTitle"

const PriceFilter: React.FC<Filter> = ({ values, isExpanded }) => {
  const [filtersState, dispatchFilters] = useContext(FiltersContext)
  const minVehicleValue = values.find(
    ({ name }) => name.toLowerCase() === "minwithdealerfee"
  )?.value
  const maxVehicleValue = values.find(
    ({ name }) => name.toLowerCase() === "maxwithdealerfee"
  )?.value
  const [minValue, setMinValue] = React.useState<string>(
    filtersState.selectedFilters.priceMin
      ? filtersState.selectedFilters.priceMin
      : minVehicleValue
  )
  const [maxValue, setMaxValue] = React.useState<string>(
    filtersState.selectedFilters.priceMax
      ? filtersState.selectedFilters.priceMax
      : maxVehicleValue
  )
  useEffect(() => {
    setMinValue(
      filtersState.selectedFilters.priceMin
        ? filtersState.selectedFilters.priceMin
        : minVehicleValue
    )
    setMaxValue(
      filtersState.selectedFilters.priceMax
        ? filtersState.selectedFilters.priceMax
        : maxVehicleValue
    )
  }, [filtersState.selectedFilters])

  //Tealium
  const {
    tealPageData,
    removeInventoryFilterFromQueue,
    addInventoryPriceFilterToQueue,
  } = useTealiumContext()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const paramValue = new Map<string, string>([
      ["min", "priceMin"],
      ["max", "priceMax"],
    ])
    const value = e.target.value.replace("$", "").replace(",", "")
    if (
      e.target.value == minValue.toString() ||
      e.target.value == maxValue.toString()
    ) {
      dispatchFilters(
        removeSelectedFilter(
          paramValue.get(e.target.name),
          filtersState.selectedFilters[paramValue.get(e.target.name)]
        )
      )
      removeInventoryFilterFromQueue({
        refinement_value: `${tealPageData.page_type}|${
          tealPageData.page_name
        }|${e.target.name} price|$${toUsd(Number(value))}`,
      })
    } else {
      dispatchFilters(
        setSelectedFilterOption(paramValue.get(e.target.name), value)
      )
      addInventoryPriceFilterToQueue(
        {
          refinement_value: `${tealPageData.page_type}|${
            tealPageData.page_name
          }|${e.target.name} price|$${toUsd(Number(value))}`,
        },
        `${e.target.name} price`
      )
    }
  }

  return (
    <>
      <FilterTitle
        title={"Price"}
        count={
          (filtersState?.selectedFilters?.priceMin ? 1 : 0) +
          (filtersState?.selectedFilters?.priceMax ? 1 : 0)
        }
        showCount={
          filtersState.selectedFilters.priceMin ||
          filtersState.selectedFilters.priceMax
        }
      />
      <Range
        min={Number(minVehicleValue)}
        max={Number(maxVehicleValue)}
        minValue={Number(minValue)}
        maxValue={Number(maxValue)}
        inputs
        tabIndex={isExpanded ? 0 : -1}
        onChange={handleChange}
      />
    </>
  )
}

export default PriceFilter
