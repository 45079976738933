import { useContext, useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import {
  setSelectedFilterOption,
  updateDealersFilter,
} from "../../../contexts/Filters/actions"
import { FiltersContext } from "../../../contexts/Filters/context"
import { InventoryContext } from "../../../contexts/Inventory/context"
import { LanguageContext } from "../../../contexts/Language"
import { LocationContext } from "../../../contexts/Location"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import AnimatedIcon from "../../atoms/Accordion/AnimatedIcon"
import { Badge } from "../../atoms/Badge"
import { Link } from "../../atoms/Link"
import { Radio } from "../../atoms/Radio"

const ByDealer = () => {
  const [{ dealers, zip }] = useContext(LocationContext)
  const [state, dispatch] = useContext(InventoryContext)
  const [filtersState, dispatchFilters] = useContext(FiltersContext)
  const content = useRef(null)
  const { _ } = useContext(LanguageContext)
  const [isExpanded, setisExpanded] = useState(false)
  const [transitionEnded, setTransitionEnded] = useState(false)
  const elementRef = useRef(null)

  useEffect(() => {
    const element = elementRef.current
    const handleTransitionEnd = () => {
      setTimeout(() => {
        setTransitionEnded(true)
      }, 700) // Set the timeout to 500ms
    }
    element?.addEventListener("transitionend", handleTransitionEnd)
    return () => {
      element?.removeEventListener("transitionend", handleTransitionEnd)
    }
  }, [])

  // Tealium
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData, swapInventoryFilterInQueue } = useTealiumContext()
  return (
    <section css={[tw`w-full`]}>
      {" "}
      <button
        type="button"
        css={[
          tw`flex justify-between items-center w-full py-4 px-4 mb-1 min-h-[1.25rem]`,
          tw`focus-visible:(outline-gray)`,
          "-webkit-tap-highlight-color: transparent;",
        ]}
        aria-expanded={isExpanded}
        onClick={() => {
          setisExpanded(!isExpanded)
          setTransitionEnded(!transitionEnded)
        }}
      >
        <h5 css={[tw`text-sm mt-0 font-semibold inline uppercase`]}>
          {_("Dealer")}
          {filtersState.currentDealerFilter && (
            <Badge color={"toyotaRed"} css={[tw` ml-3 rounded-full text-sm`]}>
              {"1"}
            </Badge>
          )}
        </h5>
        <AnimatedIcon isExpanded={isExpanded} />
      </button>
      <div
        ref={content}
        css={[
          tw`flex flex-col overflow-hidden transition-all ease-out duration-500 opacity-100 pb-10`,
          tw`md:(gap-2)`,
          isExpanded ? tw`h-auto` : tw`h-0 opacity-0`,
        ]}
      >
        {dealers?.map((dealerData, i) => {
          const selected =
            filtersState.currentDealerFilter == dealerData?.DealerCode

          return (
            <div
              css={[
                tw`p-2 rounded border border-transparent transition-all even:bg-gray-50`,
                selected && tw`shadow-2 border-gray-50`,
              ]}
              key={`dealer-${dealerData.DealerCode}`}
            >
              <Radio
                label={dealerData.Name}
                css={[
                  tw`mb-6 font-semibold text-base transition-all`,
                  selected && tw`text-red-400`,
                ]}
                name="dealers"
                value={dealerData.DealerCode}
                defaultChecked={
                  filtersState.currentDealerFilter == dealerData?.DealerCode
                }
                alignment="left"
                inputProps={{ checked: selected }}
                onChange={() => {
                  dispatchFilters(
                    setSelectedFilterOption("dealer", dealerData.DealerCode)
                  )
                  dispatchFilters(updateDealersFilter(dealerData.DealerCode))

                  swapInventoryFilterInQueue(
                    {
                      refinement_value: `${tealPageData?.page_type}|${tealPageData?.page_name}|Dealer|${dealerData.Name}`,
                      dealer_code: dealerData.DealerCode,
                      dealer_name: dealerData.Name,
                    },
                    `${tealPageData?.page_type}|${tealPageData?.page_name}|Dealer|`
                  )
                }}
                analytics-id={`dealer filter:location:${i + 1}`}
              />
              <div css={[tw`-mt-6 text-xs ml-8`]}>
                <Link
                  to={`https://www.google.com/maps/search/?api=1&query=${dealerData.Name} ${dealerData.Address1} ${dealerData.City}, ${dealerData.State} ${dealerData.Zip}`}
                  css={[
                    tw`text-toyotaRed p-0 my-1 mr-3`,
                    tw`focus-visible:outline-gray`,
                  ]}
                  animated
                  animatedThin
                  analytics-id={`dealer filter:directions:${i + 1}`}
                  onClick={() =>
                    trackTealEvent({
                      tealium_event: "get_directions",
                      dealer_name: dealerData?.Name,
                      dealer_code: dealerData?.DealerCode,
                      page_type: tealPageData.page_type,
                      customer_zip: zip,
                    })
                  }
                >
                  <div css={[tw``]}>{dealerData.Address1}</div>
                  <div css={[tw``]}>
                    {dealerData.City}, {dealerData.State} {dealerData.Zip}
                  </div>
                </Link>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default ByDealer
