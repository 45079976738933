import React, { useContext, useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import { FamilySeriesContext } from "../../../contexts/FamilySeries"
import { Family, Model, Route } from "../../../global"
import { Link } from "../../atoms/Link"
import { SeriesObject } from "../../molecules/FamilySeriesTabs/FamilySeriesTabs.d"
import { Image } from "../../atoms/Image"
import {
  generateInventoryLink,
  parseDisclaimerBlocks,
  reformatCategory,
  toUsd,
} from "../../../helpers"
import _ from "lodash"
import Icon from "../../atoms/Icon"
import { css } from "@emotion/react"
import { ThreeArrowAnimation } from "../../molecules/ThreeArrowAnimation"
import { useTealiumContext } from "../../../contexts/Tealium"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { LanguageContext } from "../../../contexts/Language"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"
import CheckMarkInCircle from "../../atoms/Icon/CheckMarkInCircle"
import { Tooltip } from "../../atoms/Tooltip"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useLocation } from "@reach/router"
import { Buffer } from "buffer"
import { InventoryContext } from "../../../contexts/Inventory/context"
import { FiltersContext } from "../../../contexts/Filters/context"

const FamilySeries: React.FC<{
  series: string
}> = ({ series }) => {
  let families = useContext(FamilySeriesContext)[0]
  const [isExpanded, setisExpanded] = useState(true)
  const [selectedSeriesSlug, setSelectedSeriesSlug] = useState(series)
  const [selectedSeriesFamily, setSelectedSeriesFamily] = useState()
  const [familyHasBeenSelected, setFamilyHasBeenSelected] = useState(
    series ? true : false
  )
  const [filtersState, dispatchFilters] = useContext(FiltersContext)
  const [{}, disclaimersDispatch] = useContext(DisclaimersContext)
  const languageContextValue = useContext(LanguageContext)
  const { _ } = useContext(LanguageContext)
  const [selectedFamily, setSelectedFamily] = useState()
  const content = useRef(null)
  const location = useLocation()
  const [state, dispatch] = useContext(InventoryContext)

  // Tealium
  const { updateVehicleTealData } = useTealiumContext()
  const { trackTealEvent } = useTealiumEvent()

  const familiesToDisplay = [
    "Cars & Minivans",
    "Crossovers & SUVs",
    "Trucks",
    "Hybrid & Electric",
  ]
  families = families?.filter((family: Family) =>
    familiesToDisplay.includes(family.name)
  )

  // order the families by the order of the familiesToDisplay array
  families = families?.sort((a: Family, b: Family) => {
    return familiesToDisplay.indexOf(a.name) - familiesToDisplay.indexOf(b.name)
  })

  useEffect(() => {
    setSelectedSeriesSlug(series)
    let isHybridFamilyParams = false
    try {
      const encodedQueryParams = location?.search?.substring(3)
      const decodedQueryParamsString = Buffer.from(
        encodedQueryParams,
        "base64"
      )?.toString("utf8")
      const decodedQueryParams =
        decodedQueryParamsString && JSON.parse(decodedQueryParamsString)
      isHybridFamilyParams =
        decodedQueryParams && decodedQueryParams?.isHybridFamilySelected
    } catch (err) {
      console.log("Error while parsing url params", err.message)
    }
    // the code below is responsible for setting the selectedFamily from the series selected
    let family = null
    if (isHybridFamilyParams && series) {
      family = familyData?.find(
        (family: any) => family?.family === "Hybrid & Electric"
      )
    } else if (series) {
      family = familyData.find((family: any) =>
        family.data.some(
          e => e?.slug?.replaceAll("/", "") === series.toLowerCase()
        )
      )
    }

    if (family && series) {
      setSelectedFamily(family)
      setSelectedSeriesFamily(family.family)
      // Data needed for Tealium view call on Series Inventory Pages:
      updateVehicleTealData({ family: family })
      // Collapse the expanded view when a series is preselected
      setisExpanded(false)
    }
  }, [series])

  // Setting initial selectedFamily for the generic inventory page
  useEffect(() => {
    if (series) return

    const storedFamily = LocalStorageClient.read("selectedFamily")
    if (!familyHasBeenSelected && storedFamily) {
      setFamilyHasBeenSelected(true)
    }

    // Using previously selected family stored in local storage if a series isn't selected.
    if (storedFamily) {
      const userPreSelectedFamily = familyData?.find(
        (family: any) => family?.family === storedFamily
      )
      setSelectedFamily(userPreSelectedFamily)
    } else {
      setSelectedFamily(familyData[0])
    }
  }, [])

  const familyData = families?.map((family: Family) => {
    if (!family) return
    return {
      family: family.name,
      data: family.routes?.map(({ route, seriesImage }: Route) => {
        const vehicle = route?.page?.vehicle
        const slug = route?.slug?.current?.replaceAll("-", "")

        let modelData = vehicle?.models?.find(
          (model: Model) =>
            model?.hasOwnProperty("featuredModel") &&
            model?.featuredModel === true
        )?.model

        if (modelData === undefined) {
          if (vehicle?.models && vehicle?.models[0]?.models) {
            // this means we are dealing with a series that exclusively has models in a group
            modelData = vehicle?.models[0]?.models[0]?.model // TODO clean this up a bit but will work for current use case
          } else {
            return
          }
        }

        // Getting Hybrid Models for inventory link and determine if series is hybrid only
        let hybridModelNumbers: string[] = []
        let isHybridOnlySeries = true
        vehicle.models.forEach(model => {
          if (model?.model) {
            // Models not in a model group
            if (model?.model?.isHybridModel) {
              hybridModelNumbers.push(model?.model?.code)
              return
            } else {
              isHybridOnlySeries = false
            }
          }

          // Model Groups
          if (model?.models) {
            model.models?.forEach(model => {
              if (model?.model) {
                if (model?.model?.isHybridModel) {
                  hybridModelNumbers.push(model?.model?.code)
                } else {
                  isHybridOnlySeries = false
                }
              }
            })
            return
          }
        })

        return {
          slug: `${slug}`,
          seriesData: modelData?.series,
          imageData: seriesImage,
          isHybrid: family.name == "Hybrid & Electric",
          hybridModelNumbers: hybridModelNumbers,
          family: family.name,
          isHybridOnlySeries: isHybridOnlySeries,
        }
      }),
    }
  })

  const getInventoryLink = (series: any) => {
    let inventoryOptions = {
      series: series?.slug.replaceAll("/", "").replaceAll("-", ""),
    }
    if (series.isHybrid) {
      inventoryOptions.modelNumber = series?.hybridModelNumbers
      inventoryOptions.isHybridFamilySelected = true
    }

    if (state?.dealer) {
      inventoryOptions.dealer = state.dealer
    }
    return generateInventoryLink(inventoryOptions)
  }

  const handleUpdateFamily = (family: any) => {
    LocalStorageClient.write("selectedFamily", family.family)
    setSelectedFamily(family)
    !familyHasBeenSelected && setFamilyHasBeenSelected(true)
    // Currently only triggering event on general /inventory page
    if (!series) {
      trackTealEvent({
        tealium_event: "refinement",
        page_name: "srp_general",
        vehicle_segment_vehicle_page: reformatCategory(family.family),
        refinement_value: `inventory srp|srp_general|Segment|${family.family}`,
      })
    }
  }

  const tabAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const focusAnimation = css`
    &:focus-visible:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
    content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:focus-visible:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const activeStyles = css`
    ${tw`font-semibold focus-visible:(border-transparent)`}
    &:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
    &:hover:after {
      ${tw`h-1 bg-red-400`}
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
  `
  // Function to close the Family Series menu on mobile
  const closeFamilySeriesMenu = () => {
    // Check if the screen width is below the mobile breakpoint
    const mobileBreakpoint = 769
    const isMobile = window.innerWidth < mobileBreakpoint

    // If the screen is on a mobile device, close Family Series
    if (isMobile && familyHasBeenSelected) {
      setisExpanded(false)
    }
  }

  useEffect(() => {
    // Call the closeFamilySeriesMenu function after the component mounts or when the page reloads
    closeFamilySeriesMenu()
  }, [])

  return (
    <section
      aria-label="Family Series Selector Section"
      css={[tw`relative bg-gradient-to-b from-gray-100`]}
    >
      <button
        type="button"
        css={[
          tw`absolute right-8 top-4 z-20`,
          tw`md:(px-0)`,
          tw`focus-visible:(outline-gray)`,
          "-webkit-tap-highlight-color: transparent;",
        ]}
        aria-expanded={isExpanded}
        onClick={() => setisExpanded(!isExpanded)}
      >
        <Icon.Chevron
          color="red-400"
          direction="down"
          css={[
            tw`h-2.5 transition-all duration-300 mt-1`,
            isExpanded && tw`transform -rotate-180`,
          ]}
        />
      </button>
      <div
        css={[
          tw`flex flex-nowrap justify-between overflow-x-auto w-4/5 py-3 scrollbar-hide`,
          tw`md:(flex justify-center pb-0 pt-4 w-full)`,
          // Padding and negative margin styles added in order to view tooltip on the generic /inventory page
          !familyHasBeenSelected &&
            isExpanded &&
            tw`pb-[500px] mb-[-500px] md:(pb-[500px] mb-[-500px])`,
        ]}
      >
        {familyData &&
          familyData.map((familyData: { family: string }, i: any) => {
            const isActive =
              selectedFamily?.family === familyData.family &&
              familyHasBeenSelected
            return (
              <div css={tw`md:relative`}>
                <button
                  css={[
                    tabAnimation,
                    focusAnimation,
                    tw`relative flex-nowrap whitespace-nowrap px-0 mx-6 py-2 cursor-pointer flex`,
                    tw`focus-visible:(outline-gray)`,
                    tw`md:(cursor-pointer flex whitespace-pre-wrap border border-transparent)`,
                    isActive && activeStyles,
                  ]}
                  onClick={() => {
                    handleUpdateFamily(familyData)
                    setisExpanded(true)
                  }}
                  analytics-id={`segment filter:model selector:${i + 1}`}
                >
                  {_(familyData.family)}
                </button>
                {i === 0 && !familyHasBeenSelected && isExpanded && (
                  <Tooltip
                    color="black"
                    css={[
                      tw`hidden w-80 max-w-full top-14 left-1/2 transform -translate-x-1/2`,
                      tw`md:(left-0 top-8 -translate-x-16 max-w-none)`,
                      tw`lg:(-translate-x-20)`,
                      isExpanded && tw`block`,
                    ]}
                  >
                    <div css={[tw`inline-block px-6 py-2`]}>
                      {languageContextValue?._(
                        "To get started select a segment like"
                      )}{" "}
                      <span css={tw`font-bold`}>
                        {languageContextValue?._("Cars & Minivans")}
                      </span>
                      {"."}
                    </div>
                  </Tooltip>
                )}
              </div>
            )
          })}
      </div>

      <div
        ref={content}
        css={[
          tw`flex justify-center items-end flex-wrap pb-4 pt-2 scrollbar-hide relative items-baseline`,
          //tw`md:(flex justify-between items-end transition-all duration-500)`,
          isExpanded
            ? tw`h-auto flex`
            : tw`h-0 opacity-0 hidden pt-0 transition-none`,
        ]}
      >
        {selectedFamily &&
          selectedFamily.data?.map((node: SeriesObject, i: number) => {
            if (!node || !node.seriesData || !node.imageData) return
            const seriesData = node?.seriesData
            const imageData = node?.imageData

            const selected =
              selectedSeriesSlug &&
              selectedSeriesSlug.toLowerCase() ===
                node.slug.replaceAll("/", "").replaceAll("-", "") &&
              (selectedSeriesFamily === node.family || node.isHybridOnlySeries)

            return (
              <div
                css={[
                  tw``,
                  tw`md:(flex flex-col items-center pb-2 focus-visible:(outline-red))`,
                ]}
                analytics-id={`series filter:model selector:${i + 1}`}
                className="optimizelyDealerExpNineSecondaryMetric"
              >
                <div
                  css={[
                    tw`flex items-center justify-between py-3`,
                    tw`md:(flex flex-col items-center focus-visible:(outline-red))`,
                    tw`lg:(mx-0)`,
                  ]}
                  className="group"
                >
                  <Link
                    to={getInventoryLink(node)}
                    className="group"
                    css={[
                      tw`mx-4 py-0 inline-block`,
                      tw`md:(flex flex-col items-center focus-visible:(outline-red))`,
                      tw`lg:(mx-0)`,
                    ]}
                    onClick={() => {
                      setSelectedSeriesFamily(node.family)
                      trackTealEvent({
                        tealium_event: "refinement",
                        page_name: "srp_general",
                        vehicle_segment_vehicle_page: reformatCategory(
                          selectedFamily.family
                        ),
                        vehicle_model_truncated: seriesData?.name,
                        refinement_value: `inventory srp|srp_general|Series|${seriesData?.name}`,
                      })
                    }}
                  >
                    {imageData && (
                      <>
                        <Image
                          imageData={imageData.image}
                          css={[tw`w-36 max-w-none`, tw`md:(w-52)`]}
                        />
                      </>
                    )}
                  </Link>
                  <div css={[tw`text-center`]}>
                    <Link
                      to={getInventoryLink(node)}
                      className="group"
                      css={[
                        tw`flex items-center justify-center mx-0 py-0 w-[165px]`,
                        tw`md:(flex flex-col items-center focus-visible:(outline-red))`,
                        tw`lg:(mx-0)`,
                      ]}
                      onClick={() => {
                        setSelectedSeriesFamily(node.family)
                        trackTealEvent({
                          tealium_event: "refinement",
                          page_name: "srp_general",
                          vehicle_segment_vehicle_page: reformatCategory(
                            selectedFamily.family
                          ),
                          vehicle_model_truncated: seriesData?.name,
                          refinement_value: `inventory srp|srp_general|Series|${seriesData?.name}`,
                        })
                      }}
                    >
                      {seriesData?.name && (
                        <div
                          css={[
                            tw`font-semibold relative`,
                            selected && tw`text-red-400`,
                          ]}
                        >
                          {seriesData?.name}
                          <ThreeArrowAnimation selected={selected} />
                        </div>
                      )}
                    </Link>

                    {seriesData?.msrp && (
                      <div css={[tw`block`]}>
                        <div css={[tw`flex-col`]}>
                          <span>$</span>
                          {toUsd(seriesData?.msrp)}
                          <span css={tw`relative`}>
                            {parseDisclaimerBlocks(`[starting_msrp]`, code =>
                              disclaimersDispatch(toggleDisclaimersModal(code))
                            )}
                          </span>
                        </div>
                        <div css={[tw`font-semibold`, "font-size: 10px"]}>
                          {languageContextValue._("Starting MSRP")}
                        </div>
                      </div>
                    )}
                    {selected && (
                      <CheckMarkInCircle
                        checkmarkColor="white"
                        color="green"
                        css={tw`w-6 mx-auto mt-2`}
                      />
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        {/* Overlay filter shown on /inventory page when family hasn't been selected */}
        <div
          css={[
            tw`bg-mystic/70 blur-sm absolute w-full h-full top-1.5 z-10 hidden`,
            !familyHasBeenSelected && isExpanded && "display: block;",
          ]}
        />
        {/* Tooltip shown on /inventory page when family has been selected but series has not */}
        <Tooltip
          color="black"
          css={[
            tw`hidden -mt-3 w-72`,
            familyHasBeenSelected && !series && "display: block;",
          ]}
        >
          <span css={tw`inline-block px-4 py-2`}>
            {languageContextValue?._(
              "Next, select a series to see inventory near you."
            )}
          </span>
        </Tooltip>
      </div>
    </section>
  )
}

export default FamilySeries
