import React, { createContext, useEffect, useReducer } from "react"
import { InventoryState } from "./inventory.d"
import inventoryReducer, { defaultState } from "./reducers"
import { Buffer } from "buffer"

export const InventoryContext = createContext<
  [InventoryState, React.Dispatch<any>]
>([defaultState, () => {}])

export const InventoryProvider: React.FC = ({ children }) => {
  let state = defaultState

  // Check to see if we're in browser
  if (typeof window !== "undefined") {
    // get the ?s= query param
    const params = new URLSearchParams(window.location.search)
    const stateEncoded = params.get("s")
    // if it has a value
    if (stateEncoded) {
      // Decode it from base64 to JSON
      const stateDecoded = Buffer.from(stateEncoded, "base64").toString("utf8")
      const urlState = JSON.parse(stateDecoded)
      // Set the default state to what we pulled from the URL
      state = urlState
    }
  }

  const [inventoryState, dispatch] = useReducer(inventoryReducer, state)

  useEffect(() => {
    // Whenever inventoryState changes, if we're in browser
    if (typeof window !== "undefined") {
      const { location, history } = window
      // Encode the full state to base64
      const stateJsonString = JSON.stringify(inventoryState)
      const base64String = Buffer.from(stateJsonString).toString("base64")
      // Set the URL to include that value for the s query param
      const url = `${location.protocol}//${location.host}${location.pathname}?s=${base64String}`
      history.replaceState(null, null, url)
    }
  }, [inventoryState])

  return (
    <InventoryContext.Provider value={[inventoryState, dispatch]}>
      {children}
    </InventoryContext.Provider>
  )
}
