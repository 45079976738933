import React, { useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import { Filter } from "../../../clients/FiltersClient.d"
import CheckboxFilterWithSuboptions from "./CheckboxFilterWithSuboptions"
import CheckboxFilterNoSuboptions from "./CheckboxFilterNoSuboptions"
import { removeDuplicateTrims } from "../../../helpers"
import { css } from "@emotion/react"

const CheckboxFilter: React.FC<Filter> = ({
  values,
  paramName,
  isExpanded,
}) => {
  const parentContainer = useRef(null)

  values = values.sort(function (a, b) {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })

  const getDisclaimerParentPosition = (): { left: number; right: number } => {
    if (window?.innerWidth < 640 || !parentContainer?.current)
      return { left: null, right: null }

    const parentContainerRect =
      parentContainer?.current?.getBoundingClientRect()

    return {
      left: parentContainerRect?.left - 8,
      right: null,
    }
  }

  return (
    <div
      css={[
        paramName == "year" && tw`flex`,
        css`
          column-count: 2;
          column-fill: balance;
          break-inside: avoid-column;
          gap: 2em;
          @media (max-width: 1024px) {
            column-count: 1;
          }
        `,
      ]}
      ref={parentContainer}
    >
      {values.map((value, index) => {
        const hasModels = value.models && value.models.length > 0
        const disclaimerParentPosition = getDisclaimerParentPosition()

        if (hasModels) {
          return (
            <CheckboxFilterWithSuboptions
              value={value}
              isExpanded={isExpanded}
              paramName={paramName}
              index={index}
            />
          )
        } else {
          return (
            <CheckboxFilterNoSuboptions
              value={value}
              active={value.active}
              isExpanded={isExpanded}
              paramName={paramName}
              index={index}
              parentLeft={disclaimerParentPosition.left}
            />
          )
        }
      })}
    </div>
  )
}

export default CheckboxFilter
