import React from "react"
import tw from "twin.macro"
import { SwitchProps } from "./Switch.d"

const Switch: React.FC<SwitchProps> = ({
  label,
  checked = false,
  onChange = () => null,
  ...remainingProps
}) => {
  return (
    <label
      htmlFor={label}
      css={[
        tw`flex items-center gap-5 text-sm leading-tight`,
        "width: fit-content",
      ]}
      {...remainingProps}
    >
      <input
        type="checkbox"
        name="toggle"
        id={label}
        checked={checked}
        onChange={onChange}
        css={[tw`h-5 w-9 absolute appearance-none cursor-pointer`]}
      />
      <span
        css={[
          tw`h-5 w-9 p-1 rounded-full bg-gray-400 shadow-3 flex items-center transition duration-100 relative pointer-events-none`,
          checked && tw`bg-red-400`,
        ]}
      >
        <span
          css={[
            tw`w-3 h-3 rounded-full bg-white absolute left-1 transition-all duration-100`,
            checked && tw`left-full -ml-4`,
          ]}
        ></span>
      </span>
      {label}
    </label>
  )
}

export default Switch
