import React from "react"
import tw from "twin.macro"
import { FiltersContext } from "../../../contexts/Filters/context"
import { useContext } from "react"
import SwitchFilter from "../../molecules/SwitchFilter/SwitchFilter"
import FilterTitle from "./FilterTitle"
import { LanguageContext } from "../../../contexts/Language"

const AvailabilityFilter: React.FC<{ sortedFilter: any }> = ({
  sortedFilter,
}) => {
  const [filtersState, dispatchFilters] = useContext(FiltersContext)

  return (
    <div css={[tw`flex flex-col gap-5`]}>
      <FilterTitle
        title={"Availability"}
        count={filtersState?.selectedFilters?.available?.length || null}
        showCount={filtersState?.selectedFilters?.available}
      />
      <div css={[tw`flex flex-row`]}>
        <SwitchFilter {...sortedFilter} />
      </div>
    </div>
  )
}
export default AvailabilityFilter
