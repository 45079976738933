import { InventoryState } from "./inventory.d"
import { ACTION_TYPES } from "./actions"

export const defaultState: InventoryState = {
  dealer: "",
  limit: 20,
  sortBy: "price|ASC",
}

const inventoryReducer = (
  state: InventoryState,
  action: { type: ACTION_TYPES; payload?: any }
) => {
  switch (action.type) {
    case ACTION_TYPES.ADD_QUERY_OPTION: {
      let currentValues: any[] = []
      if (state[action.payload.option]) {
        typeof state[action.payload.option] === "object"
          ? (currentValues = [...state[action.payload.option]])
          : (currentValues = [state[action.payload.option]])
      }
      const value = Array.isArray(action.payload.value)
        ? action.payload.value
        : [action.payload.value]
      return {
        ...state,
        [action.payload.option]: [...currentValues, ...value],
      }
    }

    case ACTION_TYPES.SET_QUERY_OPTION: {
      return {
        ...state,
        [action.payload.option]: action.payload.value,
      }
    }

    case ACTION_TYPES.REMOVE_QUERY_OPTION: {
      const currentValues = Array.isArray(state[action.payload.option])
        ? state[action.payload.option]
        : [state[action.payload.option]]

      // const indexToRemove = currentValues.indexOf(action.payload.value)
      const value = Array.isArray(action.payload.value)
        ? action.payload.value
        : [action.payload.value]

      // if value is passed as an empty array, remove all values and the param
      let newValue = []
      if (value.length !== 0) {
        newValue =
          currentValues?.filter(
            (value: any) => !action.payload.value.includes(value)
          ) || []
      }

      if (newValue.length > 0) {
        return {
          ...state,
          [action.payload.option]: newValue,
        }
      } else {
        const { [action.payload.option]: remove, ...rest } = state
        return rest
      }
    }

    case ACTION_TYPES.SET_DEALER: {
      return {
        ...state,
        dealer: action.payload,
      }
    }

    case ACTION_TYPES.SET_SORT_BY: {
      return {
        ...state,
        sortBy: action.payload,
      }
    }

    case ACTION_TYPES.SET_QUERY_FROM_FILTERS: {
      const { limit, series, sortBy, dealer, ...filters } = state

      if (action.payload.dealerFilter !== null) {
        return {
          limit,
          series,
          sortBy,
          ...action.payload.selectedFilters,
        }
      } else {
        return {
          limit,
          series,
          sortBy,
          dealer: action.payload.allDealers,
          ...action.payload.selectedFilters,
        }
      }
    }

    case ACTION_TYPES.CLEAR_ALL_FILTERS: {
      return {
        dealer: state.dealer,
        limit: state.limit,
        sortBy: state.sortBy,
      }
    }

    default:
      throw new Error()
  }
}

export default inventoryReducer
