export enum ACTION_TYPES {
  ADD_QUERY_OPTION,
  REMOVE_QUERY_OPTION,
  SET_GENERAL_FILTER,
  SET_DEALER,
  SET_SORT_BY,
  SET_QUERY_OPTION,
  CLEAR_ALL_FILTERS,
  SET_QUERY_FROM_FILTERS,
}

export const addQueryOption = (
  option: string,
  value: string | number | string[] | number[]
) => ({
  type: ACTION_TYPES.ADD_QUERY_OPTION,
  payload: {
    option,
    value,
  },
})

export const setQueryOption = (
  option: string,
  value: string | number | boolean
) => ({
  type: ACTION_TYPES.SET_QUERY_OPTION,
  payload: {
    option,
    value,
  },
})

export const removeQueryOption = (
  option: string,
  value: string | number | string[] | number[] | null
) => ({
  type: ACTION_TYPES.REMOVE_QUERY_OPTION,
  payload: {
    option,
    value,
  },
})

export const setGeneralFilter = (index: number) => ({
  type: ACTION_TYPES.SET_GENERAL_FILTER,
  payload: index,
})

export const setDealer = (dealerId: string) => ({
  type: ACTION_TYPES.SET_DEALER,
  payload: dealerId,
})

export const setSortBy = (value: string) => ({
  type: ACTION_TYPES.SET_SORT_BY,
  payload: value,
})

export const clearAllFilters = () => ({
  type: ACTION_TYPES.CLEAR_ALL_FILTERS,
})
export const setQueryFromFilters = (
  selectedFilters: { [t: string]: any },
  dealerFilter: string,
  allDealers: string
) => ({
  type: ACTION_TYPES.SET_QUERY_FROM_FILTERS,
  payload: { selectedFilters, dealerFilter, allDealers },
})
