import { navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"
import { ConfirmZipContext } from "../../../contexts/ConfirmZip"
import { closeConfirmZipModal } from "../../../contexts/ConfirmZip/actions"
import { LanguageContext } from "../../../contexts/Language"
import { LocationContext } from "../../../contexts/Location"
import { LocationSelector } from "../../molecules/LocationSelector"
import { Button } from "../../atoms/Button"
import { Modal } from "../../atoms/Modal"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"

const ConfirmZipModal = ({ fromDealersNearYou = false }) => {
  const [{ isZipModalOpen }, dispatch] = useContext(ConfirmZipContext)
  const { _ } = useContext(LanguageContext)
  const [{ zip, dealers }, locationDispatch] = useContext(LocationContext)
  const [showZipEntry, setShowZipEntry] = useState(false)
  const [zipIsConfirmed, setZipIsConfirmed] = useState(false)

  useEffect(() => {
    // If zip is undefined, prompt user to enter zip
    if (!zip) {
      setShowZipEntry(true)
    } else {
      dispatch(closeConfirmZipModal())
      setShowZipEntry(false)
    }
  }, [zip])

  useEffect(() => {
    if (zipIsConfirmed) {
      dispatch(closeConfirmZipModal())
    }
  }, [zipIsConfirmed])

  if (!isZipModalOpen) return null

  //Tealium
  const { setZipEvent } = useTealiumContext()

  return (
    <Modal
      css={[tw`max-w-2xl p-8 bg-gray-50 sm:min-w-[30rem] sm:p-12 sm:pb-16`]}
      open={isZipModalOpen}
      onClose={() => {
        dispatch(closeConfirmZipModal())
      }}
      fromDealersNearYou={fromDealersNearYou}
    >
      <h3 css={tw`text-xl`}>
        {_("Current Zip")}: <span css={[tw`font-semibold`]}>{zip}</span>
      </h3>

      {showZipEntry ? (
        <LocationSelector
          focusColor={"gray"}
          fromBanner={true}
          location="modal"
          setZipIsConfirmed={setZipIsConfirmed}
          analyticsId="zip modal"
        />
      ) : (
        <>
          <p css={[tw`mt-4`]}>
            {_("Please confirm your zip code to proceed.")}
          </p>

          <div css={[tw`flex flex-row gap-6 justify-center mt-6`]}>
            <Button
              primary
              css={[tw`whitespace-nowrap`]}
              onClick={() => {
                LocalStorageClient.write("confirmedZip", true)
                LocalStorageClient.delete("sourceZip")
                dispatch(closeConfirmZipModal())
                setZipIsConfirmed(true)
                // Zip is same because it is the "confirm" button
                setZipEvent({
                  inProgress: true,
                  prevZip: zip,
                  searchInput: zip,
                })
              }}
              analytics-id="confirm:zip modal:"
            >
              {_("Confirm")}
            </Button>
            <Button
              secondary
              css={[tw`whitespace-nowrap`]}
              onClick={() => {
                setShowZipEntry(true)
              }}
            >
              {_("Change Zip")}
            </Button>
          </div>
        </>
      )}
    </Modal>
  )
}

export default ConfirmZipModal
