import React, { useContext, useRef } from "react"
import tw from "twin.macro"
import { ColorFilterProps } from "./ColorFilter.d"
import { removeDuplicateTrims } from "../../../helpers"
import ColorSwatchInventory from "./ColorSwatchInventory"

const ColorFilter: React.FC<ColorFilterProps> = ({
  values,
  paramName,
  isExpanded,
}) => {
  const parentContainer = useRef(null)

  values = values.sort(function (a, b) {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })

  // remove duplicated values when multiple years are available
  const refinedFilters = removeDuplicateTrims(
    values,
    (m: { value: string }) => m.value
  )

  const getParentPosition = (ref: any): { left: number; right: number } => {
    if (!ref?.current) {
      return { left: null, right: null }
    }
    const parentContainerRect = ref?.current?.getBoundingClientRect()
    return {
      left: parentContainerRect?.left - 10,
      right: parentContainerRect?.right - 10,
    }
  }
  //Tealium

  const disclaimerParentPosition = getParentPosition(parentContainer)
  const parentContainerRect = parentContainer.current?.getBoundingClientRect()
  const tooltipParentPosition: { left: number | null; right: number | null } = {
    left: parentContainerRect?.left,
    right: parentContainerRect?.right,
  }
  return (
    <div
      css={[tw`flex gap-4 flex-wrap pb-20`]}
      ref={parentContainer}
      id="swatchDiv"
    >
      {refinedFilters.map((value, index) => {
        return (
          <ColorSwatchInventory
            disclaimerParentPosition={disclaimerParentPosition}
            tooltipParentPosition={tooltipParentPosition}
            value={value}
            index={index}
            paramName={paramName}
            parentContainer={parentContainer}
          />
        )
      })}
    </div>
  )
}

export default ColorFilter
