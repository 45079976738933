import { Vehicle } from "../../../global"
import { firstLettersCapital, toUsd } from "../../../helpers"

type ProcessedResults = {
  vehicle_model?: string[]
  vehicle_exterior_color?: string[]
  vehicle_year?: string[]
  vehicle_interior_color?: string[]
  vehicle_trim?: string[]
  vehicle_trim_id?: string[]
  vehicle_vin?: string[]
  vehicle_sale_price?: string[]
  search_results?: number
  vehicle_segment_vehicle_page?: string
  allocated_dealer_code?: string[]
  allocated_dealer_name?: string[]
  dealer_code?: string[]
  dealer_name?: string[]
}

/**
 * Use vehicle inventory data to create an object for Tealium calls
 *
 * @param {Array} data An array of vehicles, from the vehicle Inventory query
 * @param {number} numberOfResults The number of vehicles returned by the vehicle inventory query
 *
 * @returns {ProcessedResults}  An Object with re-formatted vehicle data
 */

export const processVehiclesData = (data: any[], numberOfResults: number) => {
  const processed: ProcessedResults = {
    vehicle_model: [],
    vehicle_exterior_color: [],
    vehicle_year: [],
    vehicle_interior_color: [],
    vehicle_trim: [],
    vehicle_trim_id: [],
    vehicle_vin: [],
    vehicle_sale_price: [],
    search_results: null,
    allocated_dealer_code: [],
    allocated_dealer_name: [],
  }

  processed.search_results = numberOfResults || 0
  // TODO: get dealer names from the API, not from Location context
  data?.forEach((vehicle: Vehicle) => {
    processed?.vehicle_model?.push(vehicle.series)
    processed?.vehicle_exterior_color?.push(vehicle?.color?.exterior?.name)
    processed?.vehicle_interior_color?.push(vehicle?.color?.interior?.name)
    processed?.vehicle_year?.push(vehicle?.year?.toString())
    processed?.vehicle_trim?.push(vehicle?.grade.toLowerCase())
    processed?.vehicle_trim_id?.push(vehicle?.modelNumber.toString())
    processed?.vehicle_vin?.push(vehicle?.vin?.toString())
    processed?.vehicle_sale_price?.push(`$${toUsd(vehicle?.msrp)}`)
    processed?.allocated_dealer_code?.push(vehicle.dealer)
    processed?.allocated_dealer_name?.push(
      firstLettersCapital(vehicle.dealerName)
    )
  })
  return processed
}
