import React, { useContext, useEffect } from "react"
import tw from "twin.macro"
import { ContactContext } from "../../../contexts/Contact"
import {
  openContactDealerModal,
  updateField,
} from "../../../contexts/Contact/actions"
import { InventoryContext } from "../../../contexts/Inventory/context"
import { LanguageContext } from "../../../contexts/Language"
import { Button } from "../../atoms/Button"
import { Filter } from "../../../clients/FiltersClient.d"
import {
  getModelNamesByAccessoryModelCodes,
  getModelNamesByModelNumbers,
  stripDisclaimerCodes,
  toUsd,
} from "../../../helpers"
import { FiltersContext } from "../../../contexts/Filters/context"

/**
 * No Exact Matches Card
 *
 * Handle logic for showing no exact matches available on search results page
 *
 * @param {Filter[]} - array of Filters for a series
 * @returns JSX
 */

const NoExactMatchesCard: React.FC<{
  seriesFilters: Filter[]
  seriesName: string
}> = ({ seriesFilters, seriesName }) => {
  const [state, dispatch] = useContext(ContactContext)
  const [filters] = useContext(InventoryContext)
  const [selectedFilters, setSelectedFilters] = React.useState([])
  const { _ } = useContext(LanguageContext)
  const [filtersState, dispatchFilters] = useContext(FiltersContext)

  // Get the formatted filter values to be displayed to the user
  const getFilterValueLabel = (key: string, value: any) => {
    const paramName = key === "accessoryModels" ? "modelNumber" : key
    const filter = seriesFilters?.find(
      (filter: Filter) => filter.paramName === paramName
    )
    let displayValue = ""

    // Formatting price to USD
    if (key == "priceMax" || key == "priceMin") {
      const price = Number(value)
      displayValue = `$${toUsd(price)}`
      return displayValue
    }

    // Return formatted value if filter does not exist in seriesFilters
    if (!filter && Array.isArray(value)) {
      return filters[key].join(", ")
    } else if (!filter) {
      const formattedValue =
        filters[key].charAt(0).toUpperCase() + filters[key].slice(1)
      return formattedValue
    }

    // Swap modelNumbers for modelNames
    if (key === "modelNumber") {
      const modelNames = getModelNamesByModelNumbers(filter, value)
      displayValue = modelNames?.join(", ")
      return displayValue
    }

    // Swap accessoryModels for modelNames
    if (key === "accessoryModels") {
      const customModelNames = getModelNamesByAccessoryModelCodes(filter, value)
      displayValue = customModelNames?.join(", ")
      return displayValue
    }

    // Default formatting
    if (!Array.isArray(value)) {
      displayValue = filter?.values?.find(
        (val: any) => val.value == value
      )?.name
    } else {
      value.forEach((filterValue, i) => {
        const selectValue = filter?.values?.find(
          (item: any) => item.value === filterValue
        )?.name
        if (selectValue) {
          const label = `${selectValue}${i !== value.length - 1 ? ", " : ""}`
          displayValue += label
        }
      })
    }

    return stripDisclaimerCodes(displayValue)
  }

  useEffect(() => {
    let excludedFilters =
      filtersState.currentDealerFilter !== null
        ? ["limit", "sortBy", "isHybridFamilySelected", "radius"]
        : ["dealer", "limit", "sortBy", "isHybridFamilySelected", "radius"]
    let filtersArray = Object.keys(filters)
      .filter(key => !excludedFilters.includes(key))
      .map(key => {
        let value = getFilterValueLabel(key, filters[key])
        return {
          key: key,
          value: value,
        }
      })
    // Combining modelNumbers and accessoryModels into Models
    const modelNumbers = filtersArray.find(
      obj => obj.key === "modelNumber"
    )?.value
    const accessoryModels = filtersArray.find(
      obj => obj.key === "accessoryModels"
    )?.value
    const modelNames = `${modelNumbers ? modelNumbers : ""}${
      modelNumbers && accessoryModels ? ", " : ""
    }${accessoryModels ? accessoryModels : ""}`
    filtersArray = filtersArray.filter(
      obj => obj.key !== "modelNumber" && obj.key !== "accessoryModels"
    )
    if (modelNames) {
      filtersArray.push({ key: "models", value: modelNames })
    }

    // Setting series to seriesName argument if it's not already set.
    const filtersSeries = filtersArray.find(obj => obj.key === "series")
    if (!filtersSeries && seriesName) {
      filtersArray.unshift({ key: "series", value: seriesName })
    }
    setSelectedFilters(filtersArray)
  }, [filters, filtersState.currentDealerFilter])

  const handleContactDealer = () => {
    dispatch(openContactDealerModal(null, null, "New Inventory Availability"))
    // Setting form's optional comments to include selected filters
    if (selectedFilters?.length > 0) {
      let formattedFiltersString = ""
      selectedFilters.forEach((filter, i) => {
        const label = filter.key.replace(/([A-Z])/g, " $1")
        formattedFiltersString += `${
          label.charAt(0).toUpperCase() + label.slice(1)
        }: ${filter.value}${i !== selectedFilters?.length - 1 ? ", " : ""}`
      })
      let comment = _("I'm interested in the") + " " + formattedFiltersString
      dispatch(updateField("comments", comment))
      const selectedSeriesName =
        selectedFilters.find(obj => obj.key === "series")?.value || seriesName
      dispatch(updateField("seriesName", selectedSeriesName))
    }
  }

  return (
    <div css={[tw`bg-gray-100 rounded-lg p-16 text-center mt-20`]}>
      {/* <Image alt="hello" src={image} css={[tw`-mt-40 w-[400px]`]} /> */}
      <p css={[tw`font-bold text-lg`]}>
        {filtersState.currentDealerFilter !== null
          ? _("Sorry, there are no exact inventory matches for your selection.")
          : _(
              "Sorry, there are no exact inventory matches for your selection within 100 of miles of your ZIP code."
            )}
      </p>
      <p>
        {_(
          "Refine your search or contact a dealer about this specific vehicle."
        )}
      </p>
      {selectedFilters?.length > 0 && (
        <p css={[tw`text-sm mt-6`]}>
          <strong>{_("Your selected filters:")}</strong>{" "}
          {selectedFilters.map((filter, i) => {
            const label = filter.key.replace(/([A-Z])/g, " $1")
            return (
              <span css={tw`capitalize`} key={`no-exact-matches-${i}`}>
                {label + ": "}
                {filter.value}
                {i !== selectedFilters.length - 1 && ", "}
              </span>
            )
          })}
        </p>
      )}
      <div css={[tw`pt-6 flex justify-center gap-4`]}>
        <Button primary onClick={handleContactDealer}>
          {_("Contact a Dealer")}
        </Button>
      </div>
    </div>
  )
}

export default NoExactMatchesCard
