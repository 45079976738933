import { useContext, useEffect, useRef } from "react"
import { processVehiclesData } from "../../components/organisms/Inventory/TealiumHelpers"
import { useTealiumContext } from "../../contexts/Tealium"
import { refineInActionDefault } from "../../contexts/Tealium/tealium.d"
import useTealiumEvent from "../Tealium/useTealiumEvent"
import useTealiumView from "../Tealium/useTealiumView"
import useTealiumNoZip from "./useTealiumNoZip"
import { InventoryContext } from "../../contexts/Inventory/context"
import { LocationContext } from "../../contexts/Location"
import { stripDisclaimerCodes, toUsd } from "../../helpers"

type InventoryInfo = {
  category: string
  seriesNameLc: string
  seriesName: string
  originalName: string
  inventoryQuery: any
}

const useRefinement = (inventoryInfo: InventoryInfo) => {
  const { category, seriesNameLc, seriesName, originalName, inventoryQuery } =
    inventoryInfo
  const [state, dispatch] = useContext(InventoryContext)
  const [{ radius }] = useContext(LocationContext)

  //   Previous results count
  const {
    inventoryResults,
    setInventoryResults,
    refineInAction,
    setRefineInAction,
    setVehicleTealData,
    clearInventoryFilterQueue,
  } = useTealiumContext()
  const firstLoad = useRef(true)
  // Custom hook to determine if user is from out of area / no set zip
  const [isOutOfArea] = useTealiumNoZip()
  const { handleTealView } = useTealiumView()
  const { trackTealEvent } = useTealiumEvent()

  useEffect(() => {
    // PAGE LOAD EVENT START
    if (firstLoad.current) {
      // OUT OF AREA USERS: For visit by users who are "out of area" and have no zip code set
      if (isOutOfArea) {
        firstLoad.current = false
        handleTealView({
          allocated_dealer_code: "NOT SET",
          allocated_dealer_name: "NOT SET",
          page_name: `${category}_${seriesNameLc}`,
          page_type: "inventory srp",
          search_results: 0,
          tealium_event: "inventory_srp",
          vehicle_exterior_color: "NOT SET",
          vehicle_interior_color: "NOT SET",
          vehicle_model: originalName,
          vehicle_model_truncated: seriesName,
          vehicle_sale_price: "NOT SET",
          vehicle_segment_vehicle_page: `${category}`,
          vehicle_trim: "NOT SET",
          vehicle_trim_id: "NOT SET",
          vehicle_vin: "NOT SET",
          vehicle_year: "NOT SET",
        })
        setVehicleTealData({
          vehicle_exterior_color: "NOT SET",
          vehicle_interior_color: "NOT SET",
          vehicle_model: originalName,
          vehicle_model_truncated: seriesName,
          vehicle_sale_price: "NOT SET",
          vehicle_segment_vehicle_page: `${category}`,
          vehicle_trim: "NOT SET",
          vehicle_trim_id: "NOT SET",
          vehicle_vin: "NOT SET",
          vehicle_year: "NOT SET",
        })
        // For IN-AREA visitors, once the inventory results are available
      } else if (inventoryQuery?.isSuccess && inventoryResults) {
        firstLoad.current = false
        const {
          vehicle_exterior_color,
          vehicle_interior_color,
          vehicle_model,
          vehicle_sale_price,
          vehicle_trim,
          vehicle_trim_id,
          vehicle_vin,
          vehicle_year,
        } = inventoryResults || {}

        handleTealView({
          ...inventoryResults,
          page_name: `${category}_${seriesNameLc}`,
          tealium_event: "inventory_srp",
          vehicle_segment_vehicle_page: category,
          vehicle_model_truncated: seriesName,
          page_type: "inventory srp",
        })
        setVehicleTealData({
          vehicle_segment_vehicle_page: category,
          vehicle_model_truncated: seriesName,
          vehicle_exterior_color,
          vehicle_interior_color,
          vehicle_model,
          vehicle_sale_price,
          vehicle_trim,
          vehicle_trim_id,
          vehicle_vin,
          vehicle_year,
        })
      } else if (inventoryQuery.status === "error") {
        firstLoad.current = false
        handleTealView({
          tealium_event: "error_page",
          page_type: "error",
          error_message: "500 error Oops! Technical Disconnect Detected",
          vehicle_model_truncated: seriesName,
          error_page: "error_page",
          page_name: "srp api error",
        })
      }
      setInventoryResults(null)
    }

    // PAGE LOAD EVENT END
  }, [isOutOfArea, inventoryResults, inventoryQuery])

  // REFINEMENT USEEFFECT
  useEffect(() => {
    // "Zip Confirmation" event - START (i.e. zip is already, provided but not yet confirmed)
    /* Conditions: 
      1. User is in area
      2. Refinement is in progress
      3. It is a "zip confirm" event, and 
      4. There must be a valid # of inventory results already loaded. */
    // Zip confirmation event depends on data that was _previously_ retrieved from Inventory query (this is why it uses "inventoryQuery" data instead of "inventoryResults" state)
    const optionalValues = {
      ...(refineInAction?.customer_zip && {
        customer_zip: refineInAction.customer_zip,
      }),
      ...(refineInAction?.refinementValue && {
        refinement_value: refineInAction.refinementValue,
      }),

      ...(refineInAction?.couponModuleText && {
        coupon_module_text: refineInAction.couponModuleText,
      }),
    }
    if (
      refineInAction?.inProgress &&
      !isOutOfArea &&
      refineInAction?.confirmZipEvent &&
      inventoryQuery?.data
    ) {
      const resultsNum1 = inventoryQuery?.data?.pages[0]?.count
      const processedVehicleData1 = processVehiclesData(
        inventoryQuery?.data?.pages[0]?.vehicles,
        resultsNum1
      )
      trackTealEvent({
        tealium_event: refineInAction?.tealiumEvent,
        vehicle_segment_vehicle_page: category,
        vehicle_model_truncated: originalName,
        ...optionalValues,
        ...processedVehicleData1,
      })

      // Re-set "refinement" action trigger
      setRefineInAction(refineInActionDefault)
      // Re-set the Tealium Inventory state after the tealium events have fired
      setInventoryResults(null)

      // --- *** "Zip Confirmation" event - END

      // Other "Refinement" Events - START
    } else if (
      refineInAction?.inProgress &&
      inventoryQuery.status !== "loading"
    ) {
      const resultsNum2 = inventoryQuery?.data?.pages[0]?.count
      const processedVehicleData2 = processVehiclesData(
        inventoryQuery?.data?.pages[0]?.vehicles,
        resultsNum2
      )
      const exteriorColorNames =
        state.exteriorColor &&
        refineInAction.filterValues
          .find(filter => filter.paramName === "exteriorColor")
          ?.values?.filter((value: { value: any }) =>
            state.exteriorColor.some((c: any) => c === value.value)
          )
          .map((color: { name: string }) => stripDisclaimerCodes(color.name))
      const interiorColorNames =
        state.interiorColor &&
        refineInAction.filterValues
          .find(filter => filter.paramName === "interiorColor")
          ?.values?.filter((value: { value: any }) =>
            state.interiorColor.some((val: any) => value.value.includes(val))
          )
          .map((color: { name: string }) => stripDisclaimerCodes(color.name))
      // The default  "refinement" event (vs "zip confirmation" event above) depends on fresh Inventory query data
      // This event is triggered in other components by refinement events
      // "Only add certain properties if they have been provided to the refineInAction object"
      trackTealEvent({
        tealium_event: refineInAction?.tealiumEvent,
        vehicle_segment_vehicle_page: category,
        vehicle_model_truncated: originalName,
        ...optionalValues,
        ...processedVehicleData2,
        ...(refineInAction?.dealerName && {
          dealer_name: refineInAction.dealerName,
        }),
        ...(refineInAction?.dealerCode && {
          dealer_code: refineInAction.dealerCode,
        }),
        search_series: state.series,
        search_exterior_color: exteriorColorNames || "",
        search_year: state.year || "",
        search_interior_color: interiorColorNames || "",
        search_trim_id: state.modelNumber || "",
        search_dealer_code: state.dealer || "",
        ...(state.priceMin || state.priceMax
          ? {
              search_sale_price: `${
                state.priceMin ? `$${toUsd(state.priceMin)}` : "not searched"
              }|${
                state.priceMax ? `$${toUsd(state.priceMax)}` : "not searched"
              }`,
            }
          : { search_sale_price: "" }),
        search_accessory: state.accessory || "",
        search_radius: radius,
        search_availability: state.available ? true : false,
      })
      // Fire all newly selected filters if provided in refinement event
      if (refineInAction?.filtersQueue?.length > 0) {
        const exteriorColorNames =
          state.exteriorColor &&
          refineInAction.filterValues
            .find(filter => filter.paramName === "exteriorColor")
            ?.values?.filter((value: { value: any }) =>
              state.exteriorColor.some((c: any) => c === value.value)
            )
            .map((color: { name: string }) => stripDisclaimerCodes(color.name))
        const interiorColorNames =
          state.interiorColor &&
          refineInAction.filterValues
            .find(filter => filter.paramName === "interiorColor")
            ?.values?.filter((value: { value: any }) =>
              state.interiorColor.some((val: any) => value.value.includes(val))
            )
            .map((color: { name: string }) => stripDisclaimerCodes(color.name))
        for (let i = 0; i <= refineInAction.filtersQueue.length - 1; i++) {
          const { coupon_module_text, ...remainingOptionalValues } =
            optionalValues
          trackTealEvent({
            tealium_event: "refinement",
            vehicle_segment_vehicle_page: category,
            vehicle_model_truncated: originalName,
            ...remainingOptionalValues,
            ...processedVehicleData2,
            ...refineInAction.filtersQueue[i],
            search_series: state.series,
            search_exterior_color: exteriorColorNames || "",
            search_year: state.year || "",
            search_interior_color: interiorColorNames || "",
            search_trim_id: state.modelNumber || "",
            search_dealer_code: state.dealer || "",
            ...(state.priceMin || state.priceMax
              ? {
                  search_sale_price: `${
                    state.priceMin
                      ? `$${toUsd(state.priceMin)}`
                      : "not searched"
                  }|${
                    state.priceMax
                      ? `$${toUsd(state.priceMax)}`
                      : "not searched"
                  }`,
                }
              : { search_sale_price: "" }),
            search_accessory: state.accessory || "",
            search_radius: radius,
            search_availability: state.available ? true : false,
          })
        }
      }
      // Re-set "refinement" action trigger
      setRefineInAction(refineInActionDefault)
      // re-set the Tealium Inventory state after the tealium events have fired
      setInventoryResults(null)
      // clear filters queue
      clearInventoryFilterQueue()
      // Other "Refinement" Events - END
    } else if (
      refineInAction?.inProgress &&
      !inventoryResults?.search_results &&
      inventoryQuery.status !== "loading"
    ) {
      // Re-set "refinement" action trigger
      setRefineInAction(refineInActionDefault)
      // re-set the Tealium Inventory state after the tealium events have fired
      setInventoryResults(null)
      //if no results are returned clear the filters queue
      clearInventoryFilterQueue()
    }
  }, [
    refineInAction?.inProgress,
    isOutOfArea,
    inventoryResults?.search_results,
    inventoryQuery.status,
  ])
}

export default useRefinement
